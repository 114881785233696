import { Grid } from '@mui/material';
import step1 from '../../../assets/images/campaign/step/step1.png';
import step2 from '../../../assets/images/campaign/step/step2.png';
import step3 from '../../../assets/images/campaign/step/step3.png';
import step4 from '../../../assets/images/campaign/step/step4.png';
import step5 from '../../../assets/images/campaign/step/step5.png';
import { TypeScreen } from '../../../utils/enum/responsive-enum';

export interface Props {
  type: string;
}

export default function StepContent(props: Props) {
  const imgDesktopStyle = {
    maxWidth: 200,
    marginRight: '2.3em',
    ...(props.type === TypeScreen.MOBILE && {
      maxWidth: '100%',
      margin: '0 5% 5% 0',
    }),
  };

  return (
    <>
      <Grid container mt={2}>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={step1} width="100%" style={imgDesktopStyle} />
        </Grid>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={step2} width="100%" style={imgDesktopStyle} />
        </Grid>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={step3} width="100%" style={imgDesktopStyle} />
        </Grid>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={step4} width="100%" style={imgDesktopStyle} />
        </Grid>
        <Grid xs={props.type === TypeScreen.MOBILE ? 6 : 2.4} pr={2}>
          <img src={step5} width="100%" style={imgDesktopStyle} />
        </Grid>
      </Grid>
    </>
  );
}
