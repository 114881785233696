import { Box, Grid, Typography } from '@mui/material';
import Content from './content';
import { TypeScreen } from '../../utils/enum/responsive-enum';
import { content } from '../../contentData/announcement';

export default function AnnouncementDesktop() {
  const heightBox = window.innerHeight - 305 || 500;

  return (
    <>
      <Box
        sx={{
          minHeight: heightBox,
          pt: 10,
        }}>
        <Grid container>
          <Grid xs={8}>
            <Typography component="div" variant="h3" sx={{ color: '#0E7F42', fontWeight: 700, mt: 4 }}>
              {content.title}
            </Typography>
          </Grid>
        </Grid>
        <Content type={TypeScreen.DESKTOP} />
      </Box>
    </>
  );
}
