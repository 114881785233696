export const productTypes = [{ type: 'โทรศัพท์มือถือ' }, { type: 'เครื่องใช้ไฟฟ้า' }];

export enum productType {
  MOBILE = 'โทรศัพท์มือถือ',
  ELECTRICAL_APPLIIANCE = 'เครื่องใช้ไฟฟ้า',
  AIR_CONDITIONER = 'แอร์',
  FRIDGE = 'ตู้เย็น',
}
export enum platform {
  CJ = 'CJ',
  TD = 'TD',
}

export enum productTypeStatus {
  YES = 'Y',
}
