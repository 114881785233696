import { Box, Typography } from '@mui/material';
import { policyContent } from '../../contentData/policy';
import BoxMobile from '../commons/ui/box-mobile';
import TextContent from './text-content';

interface Props {
  landscape: boolean;
}

export default function PolicyContentMobile({ landscape }: Props) {
  return (
    <>
      <BoxMobile>
        <Typography component="div" variant="h5">
          ประกาศความเป็นส่วนตัว
        </Typography>

        <Box ml={5}>
          <TextContent />
        </Box>
      </BoxMobile>
    </>
  );
}
