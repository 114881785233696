import { Box, Button } from '@mui/material';
import lineLogo from '../../../assets/images/campaign/line-logo.png';
import { useNavigate } from 'react-router-dom';
import { TypeScreen } from '../../../utils/enum/responsive-enum';
import { content } from '../../../contentData/kline-campaign';

export interface Props {
  type: string;
}

export default function ButtonContent(props: Props) {
  const btnLineStyle = {
    width: props.type === TypeScreen.DESKTOP ? 220 : '43%',
    height: props.type === TypeScreen.DESKTOP ? 56 : 50,
    fontWeight: 700,
    color: '#3C9967',
    border: '2px solid #3C9967',
    borderRadius: 2,
    background: '#F3F9F6',
    ...(props.type === 'mobile' && {
      fontSize: '1vh',
      ' @media screen and (max-width: 1030px)': {
        fontSize: '2vh',
      },
      ' @media screen and (max-width: 950px)': {
        fontSize: '1.1vh',
      },
      ' @media screen and (max-width: 550px)': {
        fontSize: '1.2vh',
      },

      ' @media screen and (max-width: 420px)': {
        fontSize: '1.4vh',
      },
      ' @media screen and (max-width: 330px)': {
        fontSize: '1.6vh',
        width: '50%',
      },
    }),
  };

  const btnRegisterStyle = {
    width: props.type === TypeScreen.DESKTOP ? 220 : '43%',
    height: props.type === TypeScreen.DESKTOP ? 56 : 50,
    fontWeight: 700,
    color: '#FFFFFF',
    border: '2px solid #0B8041',
    borderRadius: 2,
    background: '#0B8041',

    ...(props.type === TypeScreen.MOBILE && {
      fontSize: 12,
    }),

    '&:hover': {
      background: '#0B8041',
    },
  };

  const imgStyle = {
    width: 35,
    height: 35,
    ...(props.type === 'mobile' && {
      width: 29,
      height: 29,
    }),
  };

  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ mr: 2, ...btnRegisterStyle }}
          onClick={() => {
            navigate('/kline-form');
          }}>
          {content.homepage.lineBK.button.register}
        </Button>

        <Button
          startIcon={<img src={lineLogo} style={{ ...imgStyle }} />}
          sx={{ ...btnLineStyle }}
          onClick={() => {
            window.open(content.homepage.lineBK.button.link, '_blank');
          }}>
          <Box sx={{ display: 'block', textAlign: 'start', ml: 1 }}>
            <Box sx={{ color: '#0B8041' }}>{content.homepage.lineBK.button.text}</Box>
            <Box sx={{ color: '#323232' }}>{content.homepage.lineBK.button.idLine}</Box>
          </Box>
        </Button>
      </Box>
    </>
  );
}
