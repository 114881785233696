import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import FooterDesktop from './footer-desktop';
import FooterMobile from './footer-mobile';
import { Box } from '@mui/material';

export default function Footer() {
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: ${Responsive.DESKTOP_OR_LAPTOP} )` });
  const isBigScreen = useMediaQuery({ query: `(min-width: ${Responsive.BIGSCREEN})` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });

  return (
    <>
      <Box sx={{ maxWidth: 1230, margin: 'auto !important' }}>
        {/* {isDesktopOrLaptop && <FooterDesktop />}
      {isBigScreen && <FooterDesktop />} */}

        {!isTabletOrMobile && <FooterDesktop />}
        {isTabletOrMobile && <FooterMobile />}
      </Box>
    </>
  );
}
