import { Box } from '@mui/material';
import ContactContent from '../components/contact-us/contact-content';
import { Screen } from '../utils/enum/responsive-enum';

export default function Contact() {
  return (
    <>
      <Box sx={{ maxWidth: Screen.MAXWIDTH, margin: 'auto' }}>
        <ContactContent />
      </Box>
    </>
  );
}
