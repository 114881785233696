import { Box } from '@mui/material';
import SucceedFormBox from '../../components/campaign/form/succeed-form-box';
import { Screen } from '../../utils/enum/responsive-enum';

export default function KLineSucceedForm() {
  return (
    <>
      <Box sx={{ maxWidth: Screen.MAXWIDTH, margin: 'auto' }}>
        <SucceedFormBox />
      </Box>
    </>
  );
}
