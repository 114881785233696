const commonURL = 'https://www.kasikorncarabao.com/';

export const contentList = [
  {
    title: 'ประกาศเรื่องอัตราดอกเบี้ยผลิตภัณฑ์สินเชื่อพนักงาน',
    datePublished: '01/07/2567',
    pdfFile: `${commonURL}pdf/announcement-interest-rates-employee-loan.pdf`,
  },
  {
    title: 'ตารางการเปิดเผยข้อมูลผลิตภัณฑ์สินเชื่อเพื่อซื้อสินค้าที่เข้าร่วมรายการสำหรับโครงการสินเชื่อพนักงาน',
    datePublished: '01/07/2567',
    pdfFile: `${commonURL}pdf/product-catalogue-employee-loan.pdf`,
  },
  {
    title: 'ตารางการเปิดเผยข้อมูลผลิตภัณฑ์สินเชื่อโทรศัพท์มือถือและเครื่องใช้ไฟฟ้า',
    datePublished: '01/04/2567',
    pdfFile: `${commonURL}pdf/product-catalogue-appliance-and-smartphone-loan.pdf`,
  },
  {
    title: 'ประกาศการปรับอัตราดอกเบี้ยผิดนัดชำระหนี้และการแก้ไขเพิ่มเติมสัญญาสินเชื่อ',
    datePublished: '29/02/2567',
    pdfFile: `${commonURL}pdf/announcement-interest-rates.pdf`,
  },
  {
    title: 'ประกาศความเป็นส่วนตัว',
    datePublished: '12/04/2566',
    pdfFile: `${commonURL}pdf/PDPA-Policy.pdf`,
  },
  {
    title: 'นโยบายการใช้คุกกี้',
    datePublished: '12/04/2566',
    pdfFile: `${commonURL}pdf/Cookies-Policy.pdf`,
  },
];

export const header = {
  title: 'เรื่อง',
  date: 'วันที่ประกาศ',
};

export const content = {
  title: 'ประกาศที่เกี่ยวข้อง',
};
