import { Box, Grid, Typography } from '@mui/material';
import { Responsive, ScreenBox } from '../../utils/enum/responsive-enum';
import PriceComponent from './price-component';
import { content } from './content-data-sku';

export interface props {
  skuContent: any;
}
export default function ApplianceContentBoxMobile({ skuContent }: props) {
  const widthBox = window.innerWidth;
  let isTable = false;
  if (widthBox > 500 && widthBox <= ScreenBox.TABLE_OR_MOBILE) {
    isTable = !isTable;
  }
  return (
    <>
      <Box mt={8}>
        <Grid container>
          <Grid xs={12}>
            <Typography component="div" variant="h5" sx={{ color: '#0E7F42', fontWeight: 700 }}>
              {skuContent.title}
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Box
              sx={{
                backgroundImage: `url(${skuContent.urlImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: 170,
                width: '100%',
              }}></Box>
          </Grid>
          <Grid xs={6}>
            <Grid container>
              <Grid xs={12}>
                <Typography component="div" variant="h5" sx={{ color: '#263238' }}>
                  {skuContent.skuName}
                </Typography>
                <Typography component="div" variant="inherit" sx={{ color: '#263238' }}>
                  {skuContent.skuModel}
                </Typography>
                <Typography component="div" variant="inherit" sx={{ color: '#263238' }}>
                  {skuContent.specVal1} {skuContent.specVal2}
                </Typography>
              </Grid>

              <Grid xs={8} mt={1}>
                <Typography component="div" sx={{ color: '#626262', fontWeight: 700 }}>
                  {content.skuContent.price}
                  {'  '}
                  {skuContent.price}
                </Typography>
              </Grid>
              <Grid xs={8}>
                <Typography component="div" sx={{ color: '#263238', fontWeight: 700 }}>
                  {content.skuContent.down}
                </Typography>
                <Typography component="div" sx={{ color: '#6D6D6D', fontWeight: 700, fontSize: 12 }}>
                  {content.skuContent.downText}
                </Typography>
              </Grid>
              <Grid xs={4}>
                <Typography component="div" sx={{ color: '#F54949', fontSize: 20, fontWeight: 700 }}>
                  {skuContent.downPrice}
                </Typography>
              </Grid>
              <Grid xs={8}>
                <Typography mt={0.5} component="div" sx={{ color: '#263238', fontWeight: 700 }}>
                  {content.skuContent.installmentsText}
                </Typography>
              </Grid>
              <Grid xs={4}>
                <Typography component="div" sx={{ color: '#F54949', fontSize: 20, fontWeight: 700 }}>
                  {skuContent.installmentPrice}
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Typography component="div" sx={{ color: '#6D6D6D', fontWeight: 700, fontSize: 12 }}>
                  {skuContent.installmentTerm} {content.skuContent.interrestRate0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container mt={4}>
          <Grid xs={2}>
            <img src={skuContent.features1.icon} width={50} />
          </Grid>
          <Grid xs={10} pt={1} pl={2}>
            <strong>{skuContent.features1.title}</strong> {skuContent.features1.description}
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid xs={2} pt={2}>
            <img src={skuContent.features2.icon} width={50} />
          </Grid>
          <Grid xs={10} pt={1} pl={2}>
            <strong>{skuContent.features2.title}</strong> {skuContent.features2.description}
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid xs={2} pl={1} pt={1}>
            <img src={skuContent.features3.icon} width={40} />
          </Grid>
          <Grid xs={10} pt={1} pl={2}>
            <strong>{skuContent.features3.title}</strong> {skuContent.features3.description}
          </Grid>
        </Grid>
        <Grid container mt={2}>
          <Grid xs={2}>
            <img src={skuContent.features4.icon} width={50} />
          </Grid>
          <Grid xs={10} pt={1} pl={2}>
            <strong>{skuContent.features4.title}</strong> {skuContent.features4.description}
          </Grid>
        </Grid>
        <Box mt={2}>
          <Typography
            component="div"
            sx={{ color: '#6D6D6D', mt: 2, fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
            {content.skuContent.hypothesis}
          </Typography>
          <Typography component="div" sx={{ color: '#6D6D6D', fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
            {skuContent.remark}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
