import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  districtList: any | [];
  clear: boolean;
  onComplete: (value: any) => void;
}
export default function FilterDistrict({ districtList, clear, onComplete }: Props) {
  const [loading] = useState(false);

  const [selectDistrict, setSelectDistrict] = useState('');

  const autocompleteRenderInput = (params: any) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
        }}
        placeholder={'อำเภอ/เขต'}
        variant="standard"
        size="small"
        fullWidth
        sx={{ borderBottom: '1px solid #6D6D6D' }}
      />
    );
  };

  const districtRenderListItem = (props: any, option: any) => {
    return <li {...props}>{option.districtName}</li>;
  };

  const handleChangeItem = async (event: any, option: any, reason: any) => {
    if (option) {
      setSelectDistrict(option);

      return onComplete(option);
    }
  };

  const onInputChange = async (event: any, value: string, reason: string) => {
    if (event && event.keyCode && event.keyCode === 13) {
      return false;
    }

    if (value === '' && reason === 'input') {
      setSelectDistrict('');
      return onComplete(null);
    }
  };

  useEffect(() => {
    if (selectDistrict) {
      setSelectDistrict('');
    }
  }, [districtList]);

  return (
    <>
      <Autocomplete
        id="selmMerchantByProvince"
        disableClearable
        disabled={districtList.length > 0 ? false : true}
        value={selectDistrict}
        loadingText="กำลังโหลด..."
        loading={loading}
        options={districtList}
        renderOption={districtRenderListItem}
        renderInput={(option) => autocompleteRenderInput(option)}
        getOptionLabel={(option: any) => (option.districtName ? option.districtName : '')}
        onChange={handleChangeItem}
        onInputChange={onInputChange}
      />
    </>
  );
}
