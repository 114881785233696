import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TestState = {
  state: Number;
};

const initialState: TestState = {
  state: 1,
};

export const TestSlice = createSlice({
  name: 'test',
  initialState,
  reducers: {
    testChangeState: (state, action: PayloadAction<Number>) => {
      state.state = action.payload;
    },
  },
});

export const { testChangeState } = TestSlice.actions;
export default TestSlice.reducer;
