export const masterURL = 'https://master-reference.obs.ap-southeast-2.myhuaweicloud.com';

export const scriptURL =
  'https://script.google.com/macros/s/AKfycbz_LJ2vlTPcAO7_bpcU01YSyw8zkHRvQfHH80vBPccSIOAIXeBf3vZkDPZY8B353ZuNCA/exec';

export const reCaptCha = {
  sitekey: '6LdpO3opAAAAAFSb0igM5zEZFmQs7u7_itJ5KRl7',
};

export const content = {
  header: 'บริการของเรา',
  title: 'ผ่อนโทรศัพท์มือถือและเครื่องใช้ไฟฟ้ากับ kbao ผ่าน CJ A-Home สาขาที่ร่วมรายการได้แล้ววันนี้',
  text: 'ข้าพเจ้าตกลงให้บริษัท กสิกรไทย คาราบาว จำกัด \n (“บริษัท”)ใช้ข้อมูลข้าง',

  homepage: {
    lineBK: {
      title: 'พิเศษสำหรับลูกค้า LINE BK',
      button: {
        text: 'สอบถามเพิ่มเติม',
        idLine: 'LINE @kbao',
        link: 'https://line.me/R/ti/p/@kbao',
        register: 'สนใจสมัครสินเชื่อ',
      },
    },
    bestSelling: {
      title: 'สินค้าขายดี',
      more: 'ดูเพิ่มเติม',
    },
    step: {
      title: 'ขั้นตอนการซื้อ',
    },
    faq: {
      title: 'คำถามที่พบบ่อย',
    },
  },
  form: {
    category: {
      title: 'คุณสนใจสินค้าประเภทใดบ้าง',
      subTitle: 'เราจะนำเสนอข้อเสนอพิเศษมาให้คุณตามความสนใจ',
    },
    warningText: 'กรอกข้อมูลให้ครบถ้วนเพื่อให้เจ้าหน้าที่ติดต่อกลับ',
    title: 'คำนำหน้า',
    firstName: 'ชื่อ',
    lastName: 'นามสกุล',
    mobileNo: 'เบอร์โทรศัพท์',
    province: 'จังหวัด',
    district: 'อำเภอ/เขต',
    subdistrict: 'ตำบล/แขวง',
    policy:
      'ข้าพเจ้าตกลงให้บริษัท กสิกรไทย คาราบาว จำกัด (“บริษัท”) ใช้ข้อมูลข้างต้นในการติดต่อข้าพเจ้าเพื่อนำเสนอผลิตภัณฑ์หรือบริการของบริษัทและข้าพเจ้าได้อ่านและเข้าใจถึงการดำเนินการที่เกี่ยวข้องกับข้อมูลส่วนบุคคล ของข้าพเจ้าที่ระบุไว้ใน',
    linkText: 'ประกาศความเป็นส่วนตัวของบริษัท',
    button: {
      cancel: 'ยกเลิก',
      submit: 'ยืนยัน',
    },
    succeed: {
      title: 'ขอบคุณที่สนใจ',
      subTitle: 'โดยเจ้าหน้าที่จะดำเนินการติดต่อกลับท่านต่อไป \n ติดต่อ ณ ร้านค้าที่ร่วมรายการเพื่อดำเนินการต่อ',
      button: {
        branchesSearch: 'ค้นหาร้านค้าใกล้ฉัน',
      },
    },
  },
};
