import { Box } from '@mui/material';
import HomePageContent from '../../components/campaign/home-page/home-page-content';
import { Screen } from '../../utils/enum/responsive-enum';

export default function HomePage() {
  return (
    <>
      <Box sx={{ maxWidth: Screen.MAXWIDTH, margin: 'auto' }}>
        <HomePageContent />
      </Box>
    </>
  );
}
