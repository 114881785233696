import { ArrowDropDown } from '@mui/icons-material';
import {
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Fragment, useState } from 'react';
import { productTypes } from '../../utils/enum/search-branches-enum';
import { content } from '../../contentData/branches';

interface Props {
  onComplete: (value: any) => void;
}
export default function FilterProductType({ onComplete }: Props) {
  const [loading] = useState(false);

  const productTypeListOption = productTypes;

  const [selectProductType, setSelectProductType] = useState('0');
  const autocompleteRenderInput = (params: any) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              <InputAdornment position="start" sx={{ marginRight: '-2em' }}>
                <ArrowDropDown />
              </InputAdornment>
            </Fragment>
          ),
        }}
        placeholder={'ประเภทสินค้าทั้งหมด'}
        variant="standard"
        size="small"
        fullWidth
        sx={{ borderBottom: '1px solid #6D6D6D' }}
      />
    );
  };

  const productTypeRenderListItem = (props: any, option: any) => {
    return <li {...props}>{option.type}</li>;
  };

  const handleChangeItemProductType = async (event: SelectChangeEvent) => {
    setSelectProductType(event.target.value as string);
    return onComplete(event.target.value as string);
  };

  const onInputChange = async (event: any, value: string, reason: string) => {
    if (event && event.keyCode && event.keyCode === 13) {
      return false;
    }
    if (value === '' && reason === 'input') {
      setSelectProductType('');
      return onComplete(null);
    }
  };

  return (
    <>
      <FormControl fullWidth variant="standard">
        <Select
          labelId="selectProductType"
          id="selectProductType"
          value={selectProductType}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={handleChangeItemProductType}
          sx={{ borderBottom: '1px solid #6D6D6D', color: '#263238' }}
          placeholder={content.productType.all}>
          <MenuItem key="0" value="0">
            {content.productType.all}
          </MenuItem>
          {productTypeListOption.map((item: any) => (
            <MenuItem key={item.type} value={item.type}>
              {item.type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
