import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import ServiceContentDeskTop from './service-content-desktop';
import ServiceContentMobile from './service-content-mobile';

export default function ServiceContent() {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {!isTabletOrMobile && <ServiceContentDeskTop />}
      {isTabletOrMobile && <>{isPortrait ? <ServiceContentMobile /> : <ServiceContentMobile />}</>}
    </>
  );
}
