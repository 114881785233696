import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  provinceListOption: any;
  onComplete: (value: any) => void;
}
export default function FilterProvince({ provinceListOption, onComplete }: Props) {
  const [loading] = useState(false);

  const [selectProvince, setSelectProvince] = useState('');

  const autocompleteRenderInput = (params: any) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
        }}
        placeholder={'จังหวัด'}
        variant="standard"
        size="small"
        fullWidth
        sx={{ borderBottom: '1px solid #6D6D6D' }}
      />
    );
  };

  const provinceRenderListItem = (props: any, option: any) => {
    return <li {...props}>{option.provinceName}</li>;
  };

  const handleChangeItemProvince = async (event: any, option: any, reason: any) => {
    if (option) {
      setSelectProvince(option);

      return onComplete(option);
    }
  };

  const onInputChange = async (event: any, value: string, reason: string) => {
    if (event && event.keyCode && event.keyCode === 13) {
      return false;
    }
    if (value === '' && reason === 'input') {
      setSelectProvince('');
      return onComplete(null);
    }
  };

  useEffect(() => {
    if (selectProvince) {
      setSelectProvince('');
    }
  }, [provinceListOption]);

  return (
    <>
      <Autocomplete
        id="selmMerchantByProvince"
        disableClearable
        value={selectProvince}
        loadingText="กำลังโหลด..."
        loading={loading}
        options={provinceListOption}
        renderOption={provinceRenderListItem}
        renderInput={(option) => autocompleteRenderInput(option)}
        getOptionLabel={(option: any) => (option.provinceName ? option.provinceName : '')}
        onChange={handleChangeItemProvince}
        onInputChange={onInputChange}
      />
    </>
  );
}
