import { Box } from '@mui/material';
import ServiceContent from '../components/service/service-content';

export default function Service() {
  return (
    <>
      <Box>
        <ServiceContent />
      </Box>
    </>
  );
}
