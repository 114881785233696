import { Dialog, DialogContent } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  open: boolean;
  imgInfo: string;
  onClickClose: () => void;
}

export default function DialogImage({ open, imgInfo, onClickClose }: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => {
    setOpenDialog(false);
    onClickClose();
  };

  useEffect(() => {
    if (open) {
      setOpenDialog(true);
    }
  }, [open]);
  return (
    <>
      <Dialog maxWidth="md" fullWidth={true} open={openDialog} onClose={handleClose}>
        <DialogContent sx={{ p: 0 }}>
          <img src={imgInfo} width="100%" style={{ marginBottom: '-6px' }} />
        </DialogContent>
      </Dialog>
    </>
  );
}
