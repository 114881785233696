import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './page/home';
import Navbar from './components/header/navbar';
import Policy from './page/policy';
import About from './page/about-us';
import Contact from './page/contact-us';
import { Box } from '@mui/material';
import Footer from './components/footer/footer';
import CookiePolicy from './page/cookie-policy';
import Service from './page/service';
import BranchesSearch from './page/branches-search';
import HomePageKLineCampaign from './page/campaign/home-page';
import KLineForm from './page/campaign/kline-form';
import KLineSucceedForm from './page/campaign/succeed-form';
import Announcement from './page/announcement';
import { useEffect } from 'react';
import { masterURL } from './contentData/kline-campaign';
import HomePageKbankCampaign from './page/campaign-kbank';

export default function App() {
  useEffect(() => {
    if (!localStorage.getItem('titles')) getTitleMaster();
    if (!localStorage.getItem('subdistricts')) getSubdistrictMaster();
    if (!localStorage.getItem('districts')) getDistrictMaster();
    if (!localStorage.getItem('provinces')) getProvinceMaster();
  }, []);

  const getTitleMaster = async () => {
    await fetch(`${masterURL}/common/title.json`)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('titles', JSON.stringify(data));
      });
  };

  const getSubdistrictMaster = async () => {
    await fetch(`${masterURL}/common/subdistrict.json`)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('subdistricts', JSON.stringify(data));
      });
  };

  const getDistrictMaster = async () => {
    await fetch(`${masterURL}/common/district.json`)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('districts', JSON.stringify(data));
      });
  };

  const getProvinceMaster = async () => {
    await fetch(`${masterURL}/common/province.json`)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('provinces', JSON.stringify(data));
      });
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          zIndex: 2000,
          backgroundColor: '#0d7f42',
        }}>
        <Navbar />
      </Box>

      <main>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<Policy />}></Route>
          <Route path="/cookie-policy" element={<CookiePolicy />}></Route>
          <Route path="/about-us" element={<About />}></Route>
          <Route path="/contact-us" element={<Contact />}></Route>
          <Route path="/services" element={<Service />}></Route>
          <Route path="/branches-search" element={<BranchesSearch />}></Route>
          <Route path="/kline" element={<HomePageKLineCampaign />}></Route>
          <Route path="/kline-form" element={<KLineForm />}></Route>
          <Route path="/kline-succeed-form" element={<KLineSucceedForm />}></Route>
          <Route path="/announcement" element={<Announcement />}></Route>
          <Route path="/kbank" element={<HomePageKbankCampaign />}></Route>
        </Routes>
      </main>

      <Box
        sx={{
          width: '100%',
          color: '#FFFFFF',
          zIndex: 2000,
          backgroundColor: '#0d7f42',
        }}>
        <Footer />
      </Box>
    </>
  );
}
