import { Box } from '@mui/material';
import AnnouncementContent from '../components/announcement/announcement';
import { Screen } from '../utils/enum/responsive-enum';

export default function Announcement() {
  return (
    <>
      <Box sx={{ maxWidth: Screen.MAXWIDTH, margin: 'auto' }}>
        <AnnouncementContent />
      </Box>
    </>
  );
}
