import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import AnnouncementDesktop from './announcement-desktop';
import AnnouncementMobile from './announcement-mobile';

export default function AnnouncementContent() {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {!isTabletOrMobile && <AnnouncementDesktop />}
      {isTabletOrMobile && (
        <>{isPortrait ? <AnnouncementMobile landscape={true} /> : <AnnouncementMobile landscape={false} />}</>
      )}
    </>
  );
}
