import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../../utils/enum/responsive-enum';
import HomePageContentDesktop from './home-page-content-desktop';
import HomePageContentMobile from './home-page-content-mobile';

export default function HomePageContent() {
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: ${Responsive.DESKTOP_OR_LAPTOP} )` });
  const isBigScreen = useMediaQuery({ query: `(min-width: ${Responsive.BIGSCREEN})` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {(isDesktopOrLaptop || isBigScreen) && <HomePageContentDesktop />}

      {isTabletOrMobile && <>{isPortrait ? <HomePageContentMobile /> : <HomePageContentMobile />}</>}
    </>
  );
}
