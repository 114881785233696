import { createSlice, PayloadAction } from '@reduxjs/toolkit';
type State = {
  merchantList: [];
  error: string;
};

const initialState: State = {
  merchantList: [],
  error: '',
};
const SaveReadCSVMerchantList = createSlice({
  name: 'saveReadCSVMerchantList',
  initialState,
  reducers: {
    saveReadCSVMerchantList: (state, action: PayloadAction<any>) => {
      state.merchantList = action.payload;
    },
    clearSaveReadCSVMerchantList: (state) => {
      state.merchantList = initialState.merchantList;
    },
  },
});

export const { saveReadCSVMerchantList, clearSaveReadCSVMerchantList } = SaveReadCSVMerchantList.actions;
export default SaveReadCSVMerchantList.reducer;
