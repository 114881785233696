import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import AboutContentDeskTop from './about-content-desktop';
import AboutContentMobile from './about-content-mobile';

export default function AboutContent() {
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: ${Responsive.DESKTOP_OR_LAPTOP} )` });
  const isBigScreen = useMediaQuery({ query: `(min-width: ${Responsive.BIGSCREEN})` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {/* {isDesktopOrLaptop && <AboutContentDeskTop />}
      {isBigScreen && <AboutContentDeskTop />} */}

      {!isTabletOrMobile && <AboutContentDeskTop />}
      {isTabletOrMobile && (
        <>{isPortrait ? <AboutContentMobile landscape={true} /> : <AboutContentMobile landscape={false} />}</>
      )}
    </>
  );
}
