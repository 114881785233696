export const serviceContent = {
  header: 'บริการของเรา',
  title: 'ผ่อนโทรศัพท์มือถือและเครื่องใช้ไฟฟ้ากับ kbao ผ่าน CJ A-Home สาขาที่ร่วมรายการได้แล้ววันนี้',
  checkedVal1: 'บัตรประชาชนใบเดียว ก็สามารถสมัครได้ผ่านร้านค้าที่เข้าร่วม',
  checkedVal2: 'ผ่อนน้อย',
  checkedVal3: 'รู้ผลทันที',
  checkedVal4: 'ดอกเบี้ย 0% 6 เดือน (สำหรับแอร์  12 เดือน)',
  checkedVal5: 'กู้เท่าที่จำเป็น และชำระคืนไหว',
  remark:
    '*สมมติฐานการคำนวณจากการกู้สินเชื่อที่ผ่อนชำระเป็นงวด 6,069 บาท  (คำนวนจากเงินดาวน์ + (เงินผ่อนค่างวด x 6 เดือน)) โดนมีงวดดาวน์งวดแรกจำนวน 1,329 บาท ที่อัตราดอกเบี้ย 0% ต่อปี จะมีค่างวด 790 บาทต่อเดือน ผ่อนนาน 6 เดือน  ทั้งนี้ การพิจารณาอนุมัติและเงื่อนไขการผ่อนชำระเงิน เป็นไปตามที่บริษัท  กสิกร คาราบาว จำกัด กำหนด',

  mobileTile: 'โทรศัพท์มือถือ',
  store: 'ราคาสินค้า',

  GuarantorText: 'มีผู้ค้ำประกัน',
  NoGuarantorText: 'ไม่มีผู้ค้ำประกัน',

  faqTitle: 'คำถามที่พบบ่อย',
  brandProduct: 'แบรนด์สินค้าที่ร่วมรายการ',
};
