import { Box } from '@mui/material';
import { ReactNode } from 'react';

export interface props {
  children?: ReactNode;
}

export default function BoxMobile({ children }: props) {
  return (
    <Box
      sx={{
        p: 3,
        pt: 8,
        marginTop: '-15px',
      }}>
      {children}
    </Box>
  );
}
