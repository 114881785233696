import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import LoadingModal from '../../commons/ui/loading-modal';
import { useNavigate } from 'react-router-dom';
import AutocompleteItems from '../../commons/ui/autocomplete-items';
import CategoryContent from './category-content';
import AutocompleteAddressForm from '../../commons/ui/autocomplete-address-form';
import { content, reCaptCha, scriptURL } from '../../../contentData/kline-campaign';
import { RegexType } from '../../../utils/enum/common-enum';

declare global {
  interface Window {
    handleCaptcha: any;
    grecaptcha: any;
  }
}

type Values = {
  category: string;
  title: string;
  firstName: string;
  lastName: string;
  mobileNo: string;
  subdistrict: string;
  district: string;
  province: string;
  reCaptchaResponse: string;
};

export default function FormBox() {
  const textFieldStyle = {
    '& .MuiInputBase-input': {
      borderRadius: '2px !important',
      padding: '10px',
      minHeight: '25px',
    },
  };

  const [openLoadingModal, setOpenLoadingModal] = React.useState(false);
  const [values, setValues] = React.useState<Values>({
    category: '',
    title: '',
    firstName: '',
    lastName: '',
    mobileNo: '',
    subdistrict: '',
    district: '',
    province: '',
    reCaptchaResponse: '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'mobileNo') {
      const regex = new RegExp(RegexType.NUMBER_ONLY);
      if (regex.test(event.target.value)) setValues({ ...values, [event.target.name]: event.target.value });
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
  };

  const handleChangeCategory = (list: any) => {
    let result: string = '';
    list.map((v: any, index: number) => {
      result = result.concat(index !== 0 ? `, ${v.name}` : v.name);
    });
    setValues({ ...values, category: result });
  };

  const navigate = useNavigate();
  const handleSubmit = async () => {
    setOpenLoadingModal(true);

    let formData = new FormData();
    formData.append('Time', String(new Date()));
    formData.append('Category', values.category);
    formData.append('Title', values.title);
    formData.append('FirstName', values.firstName);
    formData.append('LastName', values.lastName);
    formData.append('MobileNo', values.mobileNo);
    formData.append('Subdistrict', values.subdistrict);
    formData.append('District', values.district);
    formData.append('Province', values.province);
    formData.append('reCaptchaResponse', values.reCaptchaResponse);

    await fetch(scriptURL, { method: 'POST', body: formData })
      .then((response) => {
        console.log('Success!', response);

        navigate('/kline-succeed-form');
      })
      .catch((error) => {
        console.log('Error!', error.message);
      });
    setOpenLoadingModal(false);
  };

  window.handleCaptcha = (token: any) => {
    setValues({ ...values, reCaptchaResponse: token });
  };

  const [chkFirstLoad, setChkFirstLoad] = React.useState<any>(false);
  const firstLoad = localStorage.getItem('firstLoad') || false;

  const [titlesList, setTitlesList] = React.useState<any>(null);
  useEffect(() => {
    if (!firstLoad && !chkFirstLoad) {
      localStorage.removeItem('_grecaptcha');
      localStorage['firstLoad'] = true;
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } else {
      localStorage.removeItem('firstLoad');
      setChkFirstLoad(true);

      const titleList = localStorage.getItem('titles');
      if (titleList) {
        const titles = JSON.parse(titleList) || [];
        setTitlesList(
          titles.map((data: any) => {
            return {
              code: data.code,
              name: data.name.th,
            };
          })
        );
      }
    }
  }, [firstLoad]);

  return (
    <>
      <Box m={2}>
        <Typography component="div" sx={{ fontSize: 24, fontWeight: 700 }}>
          {content.form.category.title}
        </Typography>
        <Typography component="div" sx={{ fontSize: 18 }}>
          {content.form.category.subTitle}
        </Typography>

        <CategoryContent
          onChange={(values: any) => {
            if (values) handleChangeCategory(values);
          }}
        />

        <Box mt={6}>
          <Typography component="div" sx={{ fontSize: 24, fontWeight: 700 }}>
            {content.form.warningText}
          </Typography>
        </Box>

        <Box mt={2}>
          <Grid container>
            <Grid xs={4}>
              <AutocompleteItems
                placeholder={content.form.title}
                defaultValue="0"
                items={titlesList}
                onChange={(value: any) => {
                  setValues({ ...values, title: value.name });
                }}
              />
            </Grid>
            <Grid xs={8} pl={2}>
              <TextField
                name="firstName"
                id="firstName"
                size="small"
                placeholder={content.form.firstName}
                value={values.firstName}
                fullWidth
                autoComplete="off"
                onChange={handleChange}
                sx={textFieldStyle}
              />
            </Grid>
          </Grid>

          <Box mt={2}>
            <TextField
              name="lastName"
              id="lastName"
              size="small"
              placeholder={content.form.lastName}
              value={values.lastName}
              fullWidth
              autoComplete="off"
              onChange={handleChange}
              sx={textFieldStyle}
            />
          </Box>

          <Box mt={2}>
            <TextField
              name="mobileNo"
              id="mobileNo"
              size="small"
              placeholder={content.form.mobileNo}
              value={values.mobileNo}
              fullWidth
              autoComplete="off"
              onChange={handleChange}
              sx={textFieldStyle}
              inputProps={{ maxLength: 10 }}
            />
          </Box>

          <AutocompleteAddressForm
            defaultValue="0"
            onChange={(value: any) => {
              if (value.subdistrictName && value.districtName && value.provinceName) {
                setValues({
                  ...values,
                  subdistrict: value.subdistrictName || '',
                  district: value.districtName || '',
                  province: value.provinceName || '',
                });
              }
            }}
          />

          <Box mt={2} sx={{ textAlign: 'justify' }}>
            {content.form.policy} &nbsp;
            <b
              style={{ color: '#0B8041', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                window.open('/privacy-policy', '_blank');
              }}>
              {content.form.linkText}
            </b>
          </Box>

          <Box mt={2}>
            <div
              id="recaptchaBox"
              className="g-recaptcha"
              data-sitekey={reCaptCha.sitekey}
              data-callback="handleCaptcha"
              style={{
                transform: 'scale(1)',
              }}></div>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 2, mb: 2 }}>
            <Button
              id="cancel"
              variant="contained"
              color="secondary"
              sx={{ mt: 2, mr: '4%', width: '48%', height: 45 }}
              onClick={() => navigate('/kline')}>
              {content.form.button.cancel}
            </Button>

            <Button
              type="submit"
              id="submit"
              variant="contained"
              disabled={
                values.firstName.length <= 0 ||
                values.lastName.length <= 0 ||
                values.mobileNo.length < 9 ||
                values.reCaptchaResponse.length <= 0
              }
              sx={{ mt: 2, width: '48%', height: 45 }}
              onClick={handleSubmit}>
              {content.form.button.submit}
            </Button>
          </Box>
        </Box>
      </Box>

      <LoadingModal open={openLoadingModal} />
    </>
  );
}
