import { MenuOutlined } from '@mui/icons-material';
import { Button, Grid, List, Menu, MenuItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import imgLogo from '../../assets/images/logo.png';
interface Props {
  landscape: boolean;
}

export default function NavbarMobile({ landscape }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  useEffect(() => {
    handleClose();
  }, [location]);

  const pathname = location.pathname.split('/')[1].toString();

  return (
    <>
      <Grid container>
        <Grid item xs={4} pl={1} pt={5} sx={{ zIndex: 1500 }}>
          <img
            src={imgLogo}
            alt="logo"
            width="100"
            style={{
              position: 'relative',
            }}
          />
        </Grid>
        <Grid item xs={8} mt={7} sx={{ textAlign: 'end' }}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              mt: -1,
              display: pathname !== 'kline-form' && pathname !== 'kline' && pathname !== 'kbank' ? '' : 'none',
            }}>
            <Typography component="div" variant="body1" sx={{ fontWeight: 700, color: '#FFFFFF' }}>
              <MenuOutlined sx={{ fontSize: 40 }} />
            </Typography>
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem>
              <List>
                <Link to="/" style={{ textDecoration: 'none', color: '#263238', textAlign: 'center' }}>
                  <Typography component="div" variant="body1">
                    หน้าแรก
                  </Typography>
                </Link>
              </List>
            </MenuItem>
            <MenuItem>
              <List>
                <Link to="/services" style={{ textDecoration: 'none', color: '#263238', textAlign: 'center' }}>
                  <Typography component="div" variant="body1">
                    บริการของเรา
                  </Typography>
                </Link>
              </List>
            </MenuItem>
            <MenuItem>
              <List>
                <Link to="/branches-search" style={{ textDecoration: 'none', color: '#263238', textAlign: 'center' }}>
                  <Typography component="div" variant="body1">
                    สาขาและร้านค้าให้บริการ
                  </Typography>
                </Link>
              </List>
            </MenuItem>
            <MenuItem>
              <List>
                <Link to="/announcement" style={{ textDecoration: 'none', color: '#263238', textAlign: 'center' }}>
                  <Typography component="div" variant="body1">
                    ประกาศที่เกี่ยวข้อง
                  </Typography>
                </Link>
              </List>
            </MenuItem>
            <MenuItem>
              <List>
                <Link
                  to="/services"
                  state={{ faq: true }}
                  style={{ textDecoration: 'none', color: '#263238', textAlign: 'center' }}>
                  <Typography component="div" variant="body1">
                    คำถามที่พบบ่อย
                  </Typography>
                </Link>
              </List>
            </MenuItem>
            <MenuItem>
              <List>
                <Link to="/about-us" style={{ textDecoration: 'none', color: '#263238', textAlign: 'center' }}>
                  <Typography component="div" variant="body1">
                    เกี่ยวกับ
                  </Typography>
                </Link>
              </List>
            </MenuItem>
            <MenuItem>
              <List>
                <Link
                  to="/contact-us"
                  state={{ faq: true }}
                  style={{ textDecoration: 'none', color: '#263238', textAlign: 'center' }}>
                  <Typography component="div" variant="body1">
                    ติดต่อ
                  </Typography>
                </Link>
              </List>
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </>
  );
}
