import { Box } from '@mui/material';

import logoCJ from '../../assets/images/search-branches/logo-cj.png';
import logoTD from '../../assets/images/search-branches/logo-td.png';
import { useState } from 'react';
import { Responsive } from '../../utils/enum/responsive-enum';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { platform } from '../../utils/enum/search-branches-enum';
import { content } from '../../contentData/branches';

const widthScreen = window.innerWidth;

const btnStyle = {
  color: '#A5A5A5',
  borderRadius: '20px',
  border: '2px solid #A5A5A5',
  background: '#F8F8F8',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const btnStyleMobile = {
  fontSize: Number(widthScreen) <= 360 ? '8px' : Number(widthScreen) <= 400 ? '10px' : '12px',
  height: '30px',
  padding: 'var(--none, 0px) var(--1, 8px)',
  gap: 'var(--1, 8px)',
  marginRight: Number(widthScreen) <= 360 ? '8px' : '18px',
};

const btnStyleDesktop = {
  fontWeight: 700,
  marginRight: '10px',
  height: '34px',
  padding: 'var(--none, 0px) var(--1, 10px)',
  gap: 'var(--1, 10px)',
};

interface Props {
  defaultPlatform: string;
  onClick: (value: any) => void;
}
export default function FilterPlatform({ defaultPlatform, onClick }: Props) {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const { state } = useLocation();
  const [value, setValue] = useState(defaultPlatform ? defaultPlatform : 'all');

  const handleStyle = (key: any) => {
    if (key === value) return { background: '#F8F8F8', color: '#0B8041', border: '2px solid #0B8041' };
    else return {};
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {!(state && state.platform === platform.CJ) && (
          <Box
            sx={{ ...btnStyle, ...(isTabletOrMobile ? btnStyleMobile : btnStyleDesktop), ...handleStyle('all') }}
            onClick={() => {
              onClick('all');
              setValue('all');
            }}>
            {content.platform.all}
          </Box>
        )}
        <Box
          sx={{ ...btnStyle, ...(isTabletOrMobile ? btnStyleMobile : btnStyleDesktop), ...handleStyle('CJ') }}
          onClick={() => {
            onClick('CJ');
            setValue('CJ');
          }}>
          <img
            src={logoCJ}
            width={isTabletOrMobile ? 20 : 28}
            height={isTabletOrMobile ? 20 : 28}
            style={{ marginRight: isTabletOrMobile ? '-3px' : '10px' }}
          />
          {content.platform.cj}
        </Box>
        {!(state && state.platform === platform.CJ) && (
          <Box
            sx={{
              ...btnStyle,
              ...(isTabletOrMobile ? btnStyleMobile : btnStyleDesktop),
              ...handleStyle('TD'),
              marginRight: '0px',
            }}
            onClick={() => {
              onClick('TD');
              setValue('TD');
            }}>
            <img
              src={logoTD}
              width={isTabletOrMobile ? 20 : 28}
              height={isTabletOrMobile ? 20 : 28}
              style={{ marginRight: isTabletOrMobile ? '-3px' : '10px' }}
            />
            {content.platform.td}
          </Box>
        )}
      </Box>
    </>
  );
}
