import { Done } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { serviceContent } from '../../contentData/service';
import BoxDesktop from '../commons/ui/box-desktop';

import aHome from '../../assets/images/service/aHome.jpeg';
import cjMore from '../../assets/images/service/cj-more.jpeg';

import samsung from '../../assets/images/service/sep/logo/samsung.png';
import haier from '../../assets/images/service/sep/logo/haier.png';
import mitsubishiElectric from '../../assets/images/service/sep/logo/mitsubishi-electric.png';
import oppo from '../../assets/images/service/sep/logo/oppo.png';
import daikin from '../../assets/images/service/sep/logo/daikin.png';
import carrier from '../../assets/images/service/sep/logo/carrier.png';

import PhoneContentBox from './phone-content-box';
import {
  daikinMaxInverter,
  mitsubishiFlatDesign,
  oppoA184G,
  oppoA3Pro5G,
  oppoA3x,
  oppoA584G,
  oppoA784G,
  oppoA985G,
  samsungGalaxyA05,
  samsungGalaxyA05s,
  samsungGalaxyA244G,
  samsungGalaxyA345G,
  tclNewMiracle,
  toshibaTopload,
  vivoY18,
} from './content-data-sku';
import ApplianceContentBox from './appliance-content-box';
import { Screen } from '../../utils/enum/responsive-enum';
import FAQBoxContent from './faq-box-content';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function ServiceContentDeskTop() {
  const maxWidthScreen = Screen.MAXWIDTH;

  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      let element: any = '';
      if (state.faq) {
        element = document.getElementById('box-faq');
      } else if (state.productSamsung) {
        element = document.getElementById('box-product-samsung');
      } else if (state.productVivo) {
        element = document.getElementById('box-product-vivo');
      }
      element = element.getBoundingClientRect();
      const heightBox = element.top + window.scrollY || window.innerHeight;
      window.scrollTo({ top: heightBox, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [state]);

  return (
    <>
      <Box>
        <BoxDesktop>
          <Grid container sx={{ maxWidth: maxWidthScreen, margin: 'auto', mt: 4 }}>
            <Grid xs={8}>
              <Typography component="div" variant="h3" sx={{ color: '#0E7F42', fontWeight: 700 }}>
                {serviceContent.header}
              </Typography>

              <Typography component="div" variant="h6" sx={{ color: '#0E7F42', mt: 1 }}>
                {serviceContent.title}
              </Typography>
            </Grid>

            <Grid xs={4} sx={{ textAlign: 'end' }}>
              <img src={cjMore} width="80" style={{ borderRadius: 10, marginRight: 15 }} />
              <img src={aHome} width="80" style={{ borderRadius: 10 }} />
            </Grid>
          </Grid>

          <Grid container sx={{ maxWidth: maxWidthScreen, margin: 'auto', mt: 4 }}>
            <Grid xs={1}></Grid>
            <Grid xs={11}>
              <Typography component="div" variant="body2" sx={{ color: '#0E7F42' }}>
                <Done sx={{ fontSize: 55, color: '#0E7F42', marginBottom: -1 }} />
                <span style={{ fontSize: 35 }}>{serviceContent.checkedVal1}</span>
              </Typography>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={6.5}>
              <Typography component="div" variant="body2" sx={{ color: '#0E7F42', mt: 2 }}>
                <Done sx={{ fontSize: 55, color: '#0E7F42', marginBottom: -1 }} />
                <span style={{ fontSize: 35 }}>{serviceContent.checkedVal2}</span>
              </Typography>
            </Grid>

            <Grid xs={4.5}>
              <Typography component="div" variant="body2" sx={{ color: '#0E7F42' }}>
                <Done sx={{ fontSize: 55, color: '#0E7F42', marginBottom: -1 }} />
                <span style={{ fontSize: 35 }}>{serviceContent.checkedVal3}</span>
              </Typography>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={6.5}>
              <Typography component="div" variant="body2" sx={{ color: '#0E7F42', mt: 2 }}>
                <Done sx={{ fontSize: 55, color: '#0E7F42', marginBottom: -1 }} />
                <span style={{ fontSize: 35 }}> {serviceContent.checkedVal4}</span>
              </Typography>
            </Grid>
            <Grid xs={4.5}>
              <Typography component="div" variant="body2" sx={{ color: '#0E7F42', mt: 2 }}>
                <Done sx={{ fontSize: 55, color: '#0E7F42', marginBottom: -1 }} />
                <span style={{ fontSize: 35 }}> {serviceContent.checkedVal5}</span>
              </Typography>
            </Grid>

            <Grid xs={1}></Grid>
          </Grid>
        </BoxDesktop>

        <Box mt={-8} mb={-12}>
          <Box sx={{ maxWidth: maxWidthScreen, margin: 'auto' }}>
            <Typography component="div" variant="h4" sx={{ color: '#0E7F42', fontWeight: 700, mb: 2 }}>
              {serviceContent.brandProduct}
            </Typography>
          </Box>

          <Box sx={{ minHeight: 150, backgroundColor: '#e4efea', textAlign: 'center', pb: 4 }}>
            <Box>
              <img src={samsung} style={{ maxWidth: 250, marginRight: '5%', marginBottom: -50 }} />
              <img src={haier} style={{ maxWidth: 170, marginRight: '5%' }} />
              <img src={mitsubishiElectric} style={{ maxWidth: 230, marginBottom: -2 }} />
            </Box>

            <Box mt={2}>
              <img src={oppo} style={{ maxWidth: 210, marginRight: '5%', marginLeft: '21%' }} />
              <img src={daikin} style={{ maxWidth: 230, marginRight: '5%' }} />
              <img src={carrier} style={{ maxWidth: 200, marginBottom: -2 }} />
            </Box>
          </Box>
        </Box>

        <BoxDesktop>
          <Box sx={{ maxWidth: maxWidthScreen + 100, margin: 'auto', mt: 12 }}>
            <Typography component="div" variant="h4" sx={{ color: '#0E7F42', fontWeight: 700 }}>
              {serviceContent.mobileTile}
            </Typography>
            <Grid container mt={3} id="box-product-samsung">
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={samsungGalaxyA05} />
              </Grid>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={samsungGalaxyA05s} />
              </Grid>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={samsungGalaxyA244G} />
              </Grid>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={samsungGalaxyA345G} />
              </Grid>
            </Grid>

            <Grid container mt={10}>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={oppoA184G} />
              </Grid>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={oppoA584G} />
              </Grid>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={oppoA784G} />
              </Grid>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={oppoA985G} />
              </Grid>
            </Grid>

            <Grid container mt={10} id="box-product-vivo">
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={oppoA3Pro5G} />
              </Grid>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={oppoA3x} />
              </Grid>
              <Grid xs={3} sx={{ textAlign: 'center' }}>
                <PhoneContentBox skuContent={vivoY18} />
              </Grid>
            </Grid>
          </Box>

          <Box mt={12}>
            <ApplianceContentBox skuContent={daikinMaxInverter} />
          </Box>
          <Box mt={12}>
            <ApplianceContentBox skuContent={tclNewMiracle} />
          </Box>

          <Box mt={12}>
            <ApplianceContentBox skuContent={toshibaTopload} />
          </Box>

          <Box mt={12}>
            <ApplianceContentBox skuContent={mitsubishiFlatDesign} />
          </Box>
        </BoxDesktop>
      </Box>

      <Box
        id="box-faq"
        sx={{
          p: 12,
          mt: '-10em',
        }}>
        <FAQBoxContent />
      </Box>
    </>
  );
}
