import { Divider, Grid, Typography } from '@mui/material';
import iconPhone from '../../assets/images/service/icon-phone.png';
import iconScreen from '../../assets/images/service/icon-screen.png';
import iconRam from '../../assets/images/service/icon-ram.png';
import iconBaterry from '../../assets/images/service/icon-baterry.png';
import { content } from './content-data-sku';

export interface props {
  skuContent: any;
}
export default function PhoneContentBoxMobile({ skuContent }: props) {
  return (
    <>
      <Grid container>
        <Grid xs={5} alignContent={'center'} pl={2} pr={2} mt={2}>
          <img src={skuContent.urlImage} width={'100%'} style={{ maxWidth: '120px' }} />
        </Grid>
        <Grid xs={7} p={1}>
          <Grid container>
            <Grid xs={12}>
              <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700, fontSize: 24 }}>
                {skuContent.title}
              </Typography>
            </Grid>
            <Grid xs={8}>
              <Typography component="div" sx={{ color: '#626262', fontWeight: 700 }}>
                {content.skuContent.price}
                {'  '}
                {skuContent.price}
              </Typography>
            </Grid>
            <Grid xs={8}>
              <Typography component="div" sx={{ color: '#263238', fontWeight: 700 }}>
                {content.skuContent.down}
              </Typography>
              <Typography component="div" sx={{ color: '#6D6D6D', fontWeight: 700, fontSize: 12 }}>
                {content.skuContent.downText}
              </Typography>
            </Grid>
            <Grid xs={4}>
              <Typography component="div" sx={{ color: '#F54949', fontSize: 20, fontWeight: 700 }}>
                {skuContent.downPrice}
              </Typography>
            </Grid>
            <Grid xs={8}>
              <Typography mt={0.5} component="div" sx={{ color: '#263238', fontWeight: 700 }}>
                {content.skuContent.installmentsText}
              </Typography>
            </Grid>
            <Grid xs={4}>
              <Typography component="div" sx={{ color: '#F54949', fontSize: 20, fontWeight: 700 }}>
                {skuContent.installmentPrice}
              </Typography>
            </Grid>
            <Grid xs={12}>
              <Typography component="div" sx={{ color: '#6D6D6D', fontWeight: 700, fontSize: 12 }}>
                {skuContent.installmentTerm} {content.skuContent.interrestRate0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid xs={1.5}>
          <img src={iconPhone} width="40" style={{ marginBottom: '-1.5em', marginRight: '0.5%' }} />
        </Grid>
        <Grid xs={4}>
          <Typography>
            {content.skuContent.screenSize}: {skuContent.screenSize}
          </Typography>
          <Typography>
            {content.skuContent.screen}: {skuContent.screen}
          </Typography>
        </Grid>
        <Grid xs={0.5}></Grid>
        <Grid xs={1.5}>
          <img src={iconScreen} width="30" style={{ marginBottom: '-1.5em', marginRight: '0.5%' }} />
        </Grid>
        <Grid xs={4} sx={{ whiteSpace: 'pre-line' }}>
          <div>{skuContent.camera}</div>
        </Grid>

        <Grid xs={1.5} mt={2}>
          <img src={iconRam} width="30" style={{ marginBottom: '-1.6em', marginRight: '0.5%' }} />
        </Grid>
        <Grid xs={4} mt={2} sx={{ whiteSpace: 'pre-line' }}>
          <div>{skuContent.memory}</div>
        </Grid>
        <Grid xs={0.5}></Grid>
        <Grid xs={1.5} mt={2}>
          <img src={iconBaterry} width="30" style={{ marginBottom: '-1.5em', marginRight: '0.5%' }} />
        </Grid>
        <Grid xs={4} mt={2} sx={{ whiteSpace: 'pre-line' }}>
          <div>{skuContent.bettery}</div>
        </Grid>
      </Grid>
      <Grid container>
        <Typography
          pl={2}
          component="div"
          sx={{ color: '#6D6D6D', mt: 2, fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
          {content.skuContent.hypothesis}
        </Typography>
        <Typography pl={2} component="div" sx={{ color: '#6D6D6D', fontSize: 12, fontWeight: 400, textAlign: 'left' }}>
          {skuContent.hypothesis}
        </Typography>
      </Grid>
      <Divider sx={{ borderColor: '#E7E7E7', mt: 2, mb: 2 }} />
    </>
  );
}
