import { Box, Card, CardContent, Grid, Pagination, Stack, Typography } from '@mui/material';
import { LocationOn } from '@mui/icons-material';
import _ from 'lodash';
import { useState } from 'react';

import logoCJ from '../../assets/images/search-branches/logo-cj.png';
import logoTD from '../../assets/images/search-branches/logo-td.png';
import iconMobile from '../../assets/images/search-branches/icon-mobile.png';
import iconElectric from '../../assets/images/search-branches/icon-electric.png';
import { platform, productTypeStatus } from '../../utils/enum/search-branches-enum';

export interface SearchProp {
  list: [];
}

const cardStyle = {
  borderRadius: '8px',
  borderTop: '3px solid #0B8041',
  background: '#F8F8F8',
};

export default function SearchBranchesListDesktop({ list }: SearchProp) {
  const handleMap = (googleMap: string) => {
    let a = document.createElement('a');
    a.target = '_blank';
    a.href = googleMap;
    a.click();
  };

  const [page, setPage] = useState(1);
  const itemPerpage = 20;
  const total = list.length;
  const countpage = Math.ceil(total / Number(itemPerpage));
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(itemPerpage);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setStartIndex(itemPerpage * (value - 1));
    setEndIndex(itemPerpage * value);
    setPage(value);
  };
  return (
    <>
      <Grid container spacing={3} rowSpacing={2}>
        {list && (
          <>
            {list.slice(startIndex, endIndex).map((merchantInfo: any) => {
              return (
                <>
                  <Grid item xs={3}>
                    <Card>
                      <CardContent sx={{ ...cardStyle, minHeight: '95px' }}>
                        {merchantInfo.Platform === platform.CJ && <img src={logoCJ} width={24} height={24} />}
                        {merchantInfo.Platform === platform.TD && <img src={logoTD} width={24} height={24} />}
                        <Typography sx={{ fontSize: '18px', fontWeight: 700, color: '#7A7A7A', minHeight: 54 }}>
                          {merchantInfo.Merchant_Name_Detail}
                        </Typography>

                        <Typography sx={{ fontSize: '16px', fontWeight: 400, color: '#7A7A7A', minHeight: 72 }}>
                          ที่อยู่ {merchantInfo.Address_Line1}{' '}
                          {merchantInfo.Province === 'กรุงเทพมหานคร' ? 'แขวง' : 'ตำบล'}
                          {merchantInfo.Subdistrict} {merchantInfo.Province === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ'}
                          {merchantInfo.District} จังหวัด{merchantInfo.Province} {merchantInfo.Zip_Code}
                        </Typography>
                      </CardContent>
                      <Box sx={{ background: '#F8F8F8' }} p={1} pb={0}>
                        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                          {merchantInfo.Mobile_Phone === productTypeStatus.YES && (
                            <>
                              <img src={iconMobile} width={24} height={24} style={{ marginRight: '8px' }} />
                              <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#7A7A7A' }} mt={0.5} mr={3}>
                                โทรศัพท์มือถือ
                              </Typography>
                            </>
                          )}
                          {merchantInfo.Electrical_Appliance === productTypeStatus.YES && (
                            <>
                              <img src={iconElectric} width={24} height={24} style={{ marginRight: '8px' }} />
                              <Typography sx={{ fontSize: '12px', fontWeight: 700, color: '#7A7A7A' }} mt={0.5}>
                                เครื่องใช้ไฟฟ้า
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Box
                          sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'end' }}
                          onClick={() => {
                            handleMap(merchantInfo.Google_map_url);
                          }}
                          mr={3}>
                          <LocationOn sx={{ color: '#008330', fontSize: 'x-large' }} />
                          <Typography sx={{ color: '#008330', fontSize: '12px', fontWeight: 700 }} pb={2}>
                            ดูแผนที่
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                </>
              );
            })}
          </>
        )}
        {/* {!merchantListTemp && (
          <>
            <Grid item container justifyContent="center" xs={12} mt={8}>
              <Typography color="#AEAEAE" variant="h6">
                ไม่พบสาขาที่ค้นหา
              </Typography>
            </Grid>
          </>
        )} */}
      </Grid>
      {list.length > 0 && (
        <>
          <Box mt={3} justifyContent={'center'} display={'flex'}>
            <Stack spacing={2}>
              <Pagination count={countpage} page={page} variant="outlined" onChange={handleChange} />
            </Stack>
          </Box>
        </>
      )}
    </>
  );
}
