import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
export interface props {
  title: string;
  children?: ReactNode;
}

export default function BoxContent({ title, children }: props) {
  return (
    <>
      <Box mt={2}>
        <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <Typography component="div" variant="body1">
          &emsp;&emsp;&emsp;{children}
        </Typography>
      </Box>
    </>
  );
}
