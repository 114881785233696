import { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import HomeJuneRelease from './home-june-release';
import HomeJuneReleaseMobile from './home-june-release-mobile';

export default function HomeContent() {
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: ${Responsive.DESKTOP_OR_LAPTOP} )` });
  const isBigScreen = useMediaQuery({ query: `(min-width: ${Responsive.BIGSCREEN})` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {(isDesktopOrLaptop || isBigScreen) && <HomeJuneRelease />}

      {isTabletOrMobile && <>{isPortrait ? <HomeJuneReleaseMobile /> : <HomeJuneReleaseMobile />}</>}
    </>
  );
}
