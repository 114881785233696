import { ArrowDropDown } from '@mui/icons-material';
import { Autocomplete, Box, CircularProgress, createFilterOptions, InputAdornment, TextField } from '@mui/material';
import _ from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { content } from '../../../contentData/kline-campaign';
import { mapAddressListNew } from '../../../utils/utils';

export interface selectProps {
  headerText?: string;
  defaultValue: any;
  onChange: (value: any) => void;
}

export default function AutocompleteAddressForm(props: selectProps) {
  const [loading] = React.useState(false);
  const [valueSubDistrict, setValueSubDistrict] = React.useState('');
  const [valueDistrict, setValueDistrict] = React.useState('');
  const [valueProvince, setValueProvince] = React.useState('');

  const [subdistrictsList, setSubdistrictsList] = React.useState<any>(null);
  const [districtsList, setDistrictsList] = React.useState<any>(null);
  const [provincesList, setProvincesList] = React.useState<any>(null);
  useEffect(() => {
    const subdistrictList = localStorage.getItem('subdistricts');
    if (subdistrictList) {
      const subdistricts = JSON.parse(subdistrictList) || null;
      setSubdistrictsList(subdistricts);
    }

    const districtList = localStorage.getItem('districts');
    if (districtList) {
      const districts = JSON.parse(districtList) || null;
      setDistrictsList(districts);
    }

    const provinceList = localStorage.getItem('provinces');
    if (provinceList) {
      const provinces = JSON.parse(provinceList) || null;
      setProvincesList(provinces);
    }
  }, []);

  let addressLists: any = [];
  if (subdistrictsList && districtsList && provincesList) {
    addressLists = mapAddressListNew(subdistrictsList, districtsList, provincesList);
  }

  let addressOptions: any = addressLists && addressLists.length > 0 ? addressLists : [];
  const subDistrictFilterOptions = createFilterOptions({ stringify: (option: any) => option.subdistrictName });
  const subDistrictRenderListItem = (props: any, option: any) => {
    return (
      <li {...props} key={option.zipCode + '-' + option.subdistrictCode}>
        {option.text}
      </li>
    );
  };

  const districtFilterOptions = createFilterOptions({ stringify: (option: any) => option.districtName });
  const districtRenderListItem = (props: any, option: any) => {
    return (
      <li {...props} key={option.subdistrictCode + '-' + option.districtCode}>
        {option.text}
      </li>
    );
  };

  const provinceFilterOptions = createFilterOptions({ stringify: (option: any) => option.provinceName });
  const provinceRenderListItem = (props: any, option: any) => {
    return (
      <li {...props} key={option.subdistrictCode + '-' + option.provinceCode}>
        {option.text}
      </li>
    );
  };

  const [itemAdd, setItemAdd] = React.useState<any>(null);
  const onInputChange = async (event: any, value: string, reason: string) => {
    if (event && event.keyCode && event.keyCode === 13) {
      return false;
    }

    if (reason === 'input') {
      const name = event?.target?.name || '-';
      let item = itemAdd || {};
      if (name === 'subdistrictName') item = { ...item, subdistrictName: value };
      else if (name === 'districtName') item = { ...item, districtName: value };
      else if (name === 'provinceName') item = { ...item, provinceName: value };

      setItemAdd(item);
      return props.onChange(item);
    }
  };

  const autocompleteRenderInput = (
    params: any,
    helperText: string = '',
    placeholder: string = '',
    name: string = '',
    error: boolean = false
  ) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              <InputAdornment position="end">
                <ArrowDropDown />
              </InputAdornment>
            </Fragment>
          ),
        }}
        variant="outlined"
        size="small"
        fullWidth
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        name={name}
        value={params.value}
        sx={{
          '& .MuiInputBase-root': {
            pr: '10px !important',
            minHeight: '45px !important',
          },
        }}
      />
    );
  };

  const handleChangeItem = async (event: any, option: any, reason: any) => {
    if (option && reason === 'selectOption') {
      setValueSubDistrict(option);
      setValueDistrict(option);
      setValueProvince(option);

      setItemAdd(option);
      return props.onChange(option);
    }
  };

  return (
    <>
      <Box mt={2}>
        <Autocomplete
          value={valueSubDistrict}
          fullWidth
          freeSolo
          loadingText="loading..."
          loading={loading}
          options={addressOptions}
          filterOptions={subDistrictFilterOptions}
          renderOption={subDistrictRenderListItem}
          onInputChange={onInputChange}
          onChange={handleChangeItem}
          getOptionLabel={(option) => (option.subdistrictName ? option.subdistrictName : '')}
          isOptionEqualToValue={(option, value) => option.text === value.text}
          renderInput={(option) => autocompleteRenderInput(option, '', content.form.subdistrict, 'subdistrictName')}
        />
      </Box>

      <Box mt={2}>
        <Autocomplete
          value={valueDistrict}
          fullWidth
          freeSolo
          loadingText="loading..."
          loading={loading}
          options={addressOptions}
          filterOptions={districtFilterOptions}
          renderOption={districtRenderListItem}
          onInputChange={onInputChange}
          onChange={handleChangeItem}
          getOptionLabel={(option) => (option.districtName ? option.districtName : '')}
          isOptionEqualToValue={(option, value) => option.text === value.text}
          renderInput={(option) => autocompleteRenderInput(option, '', content.form.district, 'districtName')}
        />
      </Box>

      <Box mt={2}>
        <Autocomplete
          value={valueProvince}
          fullWidth
          freeSolo
          loadingText="loading..."
          loading={loading}
          options={addressOptions}
          filterOptions={provinceFilterOptions}
          renderOption={provinceRenderListItem}
          onInputChange={onInputChange}
          onChange={handleChangeItem}
          getOptionLabel={(option) => (option.provinceName ? option.provinceName : '')}
          isOptionEqualToValue={(option, value) => option.text === value.text}
          renderInput={(option) => autocompleteRenderInput(option, '', content.form.province, 'provinceName')}
        />
      </Box>
    </>
  );
}
