import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      // main: '#0E7F42',
      main: '#0d7f42',
    },
    secondary: {
      main: '#AEAEAE',
    },
    error: {
      main: '#F54949',
    },
    warning: {
      main: '#FBA600',
    },
    info: {
      main: '#446EF2',
    },
    success: {
      main: '#36c690',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Kanit',
  },
});

export default theme;
