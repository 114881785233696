import React, { ReactNode } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Add, Minimize } from '@mui/icons-material';

export interface Props {
  id: string;
  defaultExpanded: string;
  headerText: string;
  children: ReactNode;
}

export default function AccordionText(props: Props) {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Accordion expanded={expanded === props.id} onChange={handleChange(props.id)}>
        <AccordionSummary
          expandIcon={expanded === props.id ? <Minimize /> : <Add />}
          aria-controls={`${props.id}d-content`}
          id={`${props.id}d-header`}
          sx={{
            background: '#F3F5F8',
          }}>
          <Typography>{props.headerText}</Typography>
        </AccordionSummary>
        <AccordionDetails>{props.children}</AccordionDetails>
      </Accordion>
    </>
  );
}
