import CookieConsent from 'react-cookie-consent';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { cookieContent } from '../../../contentData/cookie';
import { Responsive } from '../../../utils/enum/responsive-enum';

export default function CookieConsentTab() {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });

  return (
    <>
      <CookieConsent
        location="bottom"
        cookieName="KbaoCookieConsent"
        style={{
          background: '#FFFFFF',
          color: '#030303',
          zIndex: 1200,
          fontFamily: 'Kanit',
          fontSize: isTabletOrMobile ? '12px' : '16px',
          boxShadow: '0px 2px 4px rgba(0,0,0,0.2), 0px -2px 4px rgba(0,0,0,0.2)',
          minHeight: 98,
        }}
        buttonStyle={{ background: '#0E7F42', color: '#FFFFFF', fontSize: '13px' }}
        expires={150}
        buttonText={cookieContent.btnAccept}>
        {cookieContent.text}
        <Link to="/cookie-policy" style={{ textDecoration: 'none', color: '#0E7F42' }}>
          {cookieContent.link}
        </Link>
      </CookieConsent>
    </>
  );
}
