import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { content } from '../../../contentData/kline-campaign';
import { TypeScreen } from '../../../utils/enum/responsive-enum';
import CarousalImage from './carousal-image';

export interface Props {
  type: string;
}

export default function BestSellingContent(props: Props) {
  const btnSeeMoreStyle = {
    width: props.type === TypeScreen.DESKTOP ? 380 : '90%',
    height: props.type === TypeScreen.DESKTOP ? 56 : 50,
    fontWeight: 700,
    color: '#0B8041',
    border: '2px solid #3C9967',
    borderRadius: 2,
    background: '#F3F9F6',
  };

  const navigate = useNavigate();

  return (
    <>
      <Box mb={4}>
        <CarousalImage type={props.type} />
      </Box>
      <Box mb={6} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          sx={{ ...btnSeeMoreStyle }}
          onClick={() => {
            navigate('/services');
          }}>
          {content.homepage.bestSelling.more}
        </Button>
      </Box>
    </>
  );
}
