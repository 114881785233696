import { Box, Button, Card, CardContent, Grid, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { ArrowRightAlt } from '@mui/icons-material';
import CarousalImageMobile from '../commons/ui/carousal-image-mobile';

const btnStyle = {
  marginTop: '2em',
  fontWeight: 700,
  color: '#FFFFFF',
  width: '50%',
  border: '2px solid #0d7f42',
  background: '#0d7f42',
};

export default function HomeJuneReleaseMobile() {
  const theme = useTheme();

  return (
    <>
      <Card sx={{ mt: '10%', pl: 1, pr: 1, boxShadow: 'none' }}>
        <CardContent sx={{ p: 0 }}>
          <Grid container sx={{ p: 0 }}>
            <Grid xs={12} sx={{ background: '#fafafa', p: 5 }}>
              <Typography component="div" variant="h3" sx={{ fontWeight: 700, color: '#0E7F42', mt: 4 }}>
                ซีเจ ผ่อนได้ <br /> สบายกระเป๋า
              </Typography>
              {/* <Typography component="div" variant="h5" mt={2}>
                ผ่อนมือถือด้วยบัตรประชาชนใบเดียว
              </Typography>
              <Typography variant="h6" color="text.secondary" component="div">
                ได้แล้วที่ ซีเจ มอร์ 5 สาขาที่ร่วมรายการ!
              </Typography>
              <Typography component="div" variant="inherit" mt={2}>
                ผ่อนเริ่มต้นที่ <strong>799</strong> บาทต่อเดือน
                <br />
                ดาว์นเริ่มต้นที่ <b>999</b> บาท
              </Typography> */}

              <Box sx={{ borderBottom: '2px solid #0d7f42', mt: 2, width: '80%' }}></Box>
              <Typography component="div" variant="body1" mt={2}>
                ผ่อนมือถือและเครื่องใช้ไฟฟ้าด้วยบัตรประชาชนใบเดียว
              </Typography>

              <Typography component="div" variant="body1">
                ได้แล้วที่ CJ A-Home สาขาที่ร่วมรายการได้แล้ววันนี้!
              </Typography>

              <Button
                endIcon={<ArrowRightAlt />}
                variant="contained"
                color="success"
                component={Link}
                to={'/services'}
                style={btnStyle}>
                อ่านเพิ่มเติม
              </Button>
            </Grid>
          </Grid>

          <Grid container sx={{ p: 0, mb: 3 }}>
            <Grid xs={12} pl={2} pr={2}>
              <CarousalImageMobile />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
