import { Box } from '@mui/material';
import AboutContent from '../components/about-us/about-content';
import { Screen } from '../utils/enum/responsive-enum';

export default function About() {
  return (
    <>
      <Box sx={{ maxWidth: Screen.MAXWIDTH, margin: 'auto' }}>
        <AboutContent />
      </Box>
    </>
  );
}
