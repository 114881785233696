import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  districtList: any;
  error: string;
};

const initialState: State = {
  districtList: {
    all: [],
    cj: [],
    td: [],
  },
  error: '',
};

const districtListSlice = createSlice({
  name: 'SubDistrictList',
  initialState,
  reducers: {
    clearDistrictList: (state) => {
      state.districtList = initialState.districtList;
    },
    saveDistrictList: (state, action: PayloadAction<any>) => {
      state.districtList = action.payload;
    },
  },
});

export const { clearDistrictList, saveDistrictList } = districtListSlice.actions;
export default districtListSlice.reducer;
