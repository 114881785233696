export const aboutUsContent = {
  header: 'เกี่ยวกับเรา',
  title: 'ภาพรวมบริษัท',
  content1:
    'บริษัท กสิกร คาราบาว จำกัด (บริษัทฯ) เป็นบริษัทร่วมทุนระหว่าง ธนาคารกสิกรไทย จำกัด (มหาชน) และ กลุ่มธุรกิจคาราบาว (ประกอบด้วย บริษัท คาราบาว กรุ๊ป จำกัด (มหาชน), บริษัท ซี.เจ. เอ็กซ์เพรส กรุ๊ป จำกัด และบริษัท ทีดี ตะวันแดง จำกัด) ก่อตั้งขึ้นอย่างเป็นทางการในปี พ.ศ. 2565 โดยมีวัตถุประสงค์เพื่อให้บริการด้านการเงินแก่ลูกค้าและพันธมิตรใน',
  content2:
    'กลุ่มธุรกิจคาราบาว ซึ่งหมายรวมถึงผู้บริโภครายย่อยในระดับชุมชน/ตำบล โดยบริการทางการเงินดังกล่าวจะประกอบไปด้วยสินเชื่อ และการชำระเงินในรูปแบบต่างๆ ที่จะให้บริการผ่านช่องทางของร้านโชห่วยและร้านสะดวกซื้อของกลุ่มธุรกิจคาราบาวเป็นหลัก ได้แก่ ร้านถูกดี มีมาตรฐาน และร้านซีเจ มอร์ และ/หรือ ร้านซีเจ ซูเปอร์มาร์เก็ต ในรูปแบบที่ผสมผสานระหว่าง Online และ Offline เพื่อให้ตอบโจทย์และสอดคล้องกับพฤติกรรมของผู้บริโภคในกลุ่มนี้',
  policy: {
    title: 'วิสัยทัศน์ของเรา',
    content: '"บริการทางการเงินโดยชุมชน เพื่อชุมชน"',
  },
};
