import { Box, Typography } from '@mui/material';
import { policyContent } from '../../contentData/policy';
import BoxDesktop from '../commons/ui/box-desktop';
import TextContent from './text-content';

export default function PolicyContentDeskTop() {
  return (
    <>
      <BoxDesktop>
        <Typography component="div" variant="h4">
          ประกาศความเป็นส่วนตัว
        </Typography>

        <Box ml={10}>
          <TextContent />
        </Box>
      </BoxDesktop>
    </>
  );
}
