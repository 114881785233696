import _ from 'lodash';
import Papa from 'papaparse';

export const ReadCSVFile = (pathFile: string) => {
  let getValCSV: any = [];
  Papa.parse(pathFile, {
    header: true,
    download: true,
    skipEmptyLines: true,
    delimiter: ',',
    complete: (results: any) => {
      console.log(results.data);
      getValCSV = results.data;
    },
    error: (err: Error) => {
      console.log(err);
      return err;
    },
  });
  console.log('getValCSV = ', getValCSV);
};

export const formattedCurrency = (number: any) => {
  const formattedNumber = number.toFixed(2);
  const parts = formattedNumber.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return parts.join('.');
};

export const trimObject = (obj: any) => {
  const transformed = _.each(obj, (o) => _.each(o, (v, k) => (o[k] = v.trim())));

  return transformed;
};

export const mapAddressListNew = (subdistrictItems: any, districtItems: any, provinceItems: any) => {
  let addressList: any = [];
  subdistrictItems.forEach((data: any) => {
    const districtItem = _.filter(districtItems, { code: data.districtCode });
    const provinceItem = _.filter(provinceItems, { code: districtItem[0].provinceCode });

    const subdistrictName = data.name.th ?? '';
    const districtName = districtItem[0].name?.th ?? '';
    const provinceName = provinceItem[0].name?.th ?? '';
    const subdistrictCode = data.code ? data.code : '';
    const districtCode = districtItem[0].code ?? '';
    const provinceCode = provinceItem[0].code ?? '';
    const zipCode = data.zipCodes[0] ? data.zipCodes[0] : '';

    const addressText = `${subdistrictName} >> ${districtName} >> ${provinceName} >> ${zipCode}`;

    const item = {
      subdistrictCode: subdistrictCode,
      subdistrictName: subdistrictName,
      districtCode: districtCode,
      districtName: districtName,
      provinceCode: provinceCode,
      provinceName: provinceName,
      zipCode: zipCode,
      text: addressText,
    };
    addressList.push(item);
  });
  return addressList;
};
