import { Box } from '@mui/material';
import HomeContent from '../components/home/home-content';
import { Screen } from '../utils/enum/responsive-enum';

export default function Home() {
  return (
    <>
      <Box sx={{ maxWidth: Screen.MAXWIDTH, margin: 'auto' }}>
        <HomeContent />
      </Box>
    </>
  );
}
