import { AppBar } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import NavbarDesktop from './navbar-desktop';
import NavbarMobile from './navbar-mobile';

export default function Navbar() {
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: ${Responsive.DESKTOP_OR_LAPTOP} )` });
  const isBigScreen = useMediaQuery({ query: `(min-width: ${Responsive.BIGSCREEN})` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      <AppBar
        position="static"
        color="primary"
        sx={{ minHeight: 100, maxHeight: 100, maxWidth: 1230, margin: 'auto !important', boxShadow: 'none' }}>
        {/* {isDesktopOrLaptop && <NavbarDesktop />}
        {isBigScreen && <NavbarDesktop />} */}

        {!isTabletOrMobile && <NavbarDesktop />}
        {isTabletOrMobile && <>{isPortrait ? <NavbarMobile landscape={true} /> : <NavbarMobile landscape={false} />}</>}
      </AppBar>
    </>
  );
}
