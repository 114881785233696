import { Box, Typography } from '@mui/material';
import BoxMobile from '../commons/ui/box-mobile';
import { useState } from 'react';
import Content from './content';
import { TypeScreen } from '../../utils/enum/responsive-enum';
import { content } from '../../contentData/announcement';

interface Props {
  landscape: boolean;
}
export default function AnnouncementMobile({ landscape }: Props) {
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const handleDownloadFile = async (urlFile: string) => {
    setOpenLoadingModal(true);

    let a = document.createElement('a');
    a.href = urlFile;
    a.target = '_blank';
    a.click();

    setTimeout(() => {
      setOpenLoadingModal(false);
    }, 300);
  };
  const heightBox = window.innerHeight - 380 || 500;
  return (
    <>
      <BoxMobile>
        <Box
          sx={{
            minHeight: heightBox,
          }}>
          <Typography component="div" variant="h4" sx={{ color: '#0E7F42', fontWeight: 700, mt: 4 }}>
            {content.title}
          </Typography>

          <Content type={TypeScreen.MOBILE} />
        </Box>
      </BoxMobile>
    </>
  );
}
