import { Box, Typography } from '@mui/material';
import { serviceContent } from '../../contentData/service';
import FAQContent from '../faqs/faq-content';

export default function FAQBoxContent() {
  return (
    <Box mt={4}>
      <Typography component="div" variant="h4" sx={{ color: '#0E7F42', fontWeight: 700, mb: 4 }}>
        {serviceContent.faqTitle}
      </Typography>

      <FAQContent />
    </Box>
  );
}
