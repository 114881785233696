import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  provinceList: any;
  error: string;
};

const initialState: State = {
  provinceList: {
    all: [],
    cj: [],
    td: [],
  },
  error: '',
};

const provinceListSlice = createSlice({
  name: 'provinceList',
  initialState,
  reducers: {
    clearProvinceList: (state) => {
      state.provinceList = initialState.provinceList;
    },
    saveProvinceList: (state, action: PayloadAction<any>) => {
      state.provinceList = action.payload;
    },
  },
});

export const { clearProvinceList, saveProvinceList } = provinceListSlice.actions;
export default provinceListSlice.reducer;
