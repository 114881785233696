import { Box, Grid, Typography } from '@mui/material';
import { content } from './content-data-sku';

export interface Priceprops {
  skuContent?: any;
  isAppliance?: boolean;
  isAircondition?: boolean;
  isTable?: boolean;
}

export default function PriceComponent({ skuContent, isAppliance, isAircondition, isTable }: Priceprops) {
  return (
    <>
      <Box sx={{ display: isAppliance ? '' : 'none', marginLeft: isAircondition ? '18%' : '' }}>
        <Grid container sx={{ maxWidth: 500, textAlign: 'left' }}>
          <Grid item xs={isTable ? 5 : 1}></Grid>
          <Grid item xs={isTable ? 7 : 11} mt={1.5}>
            <Typography component="div" sx={{ color: '#626262', fontWeight: 700 }}>
              {content.skuContent.price}
              {'  '}
              {skuContent.price}
            </Typography>
          </Grid>
          <Grid item xs={isTable ? 5 : 1}></Grid>
          <Grid item xs={isTable ? 4 : 6} mt={1.5}>
            <Typography
              sx={{
                color: '#263238',
                fontWeight: 700,
              }}>
              {content.skuContent.down} {content.skuContent.downText}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              sx={{
                color: '#F54949',
                fontWeight: 700,
                fontSize: 28,
              }}>
              <span>{skuContent.downPrice}</span>
            </Typography>
          </Grid>
          <Grid item xs={isTable ? 0 : 2}></Grid>

          <Grid xs={isTable ? 5 : 1}></Grid>
          <Grid xs={isTable ? 4 : 6}>
            <Typography
              sx={{
                color: '#263238',
                fontWeight: 700,
              }}>
              {content.skuContent.installmentsText}
            </Typography>
            <Box>
              <Typography
                component="span"
                sx={{
                  color: '#505050',
                  fontWeight: 700,
                  fontSize: 12,
                }}>
                {skuContent.installmentTerm} {content.skuContent.interrestRate0}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={3}>
            <Typography
              sx={{
                color: '#F54949',
                fontWeight: 700,
                fontSize: 28,
              }}>
              <span>{skuContent.installmentPrice}</span>
            </Typography>
          </Grid>
          <Grid item xs={isTable ? 0 : 2}></Grid>
        </Grid>
      </Box>

      <Box sx={{ display: isAppliance ? 'none' : '' }}>
        <Grid container sx={{ maxWidth: '100%', textAlign: 'left' }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={11} mt={1.5}>
            <Typography component="div" sx={{ color: '#626262', fontWeight: 700 }}>
              {content.skuContent.price}
              {'  '}
              {skuContent.price}
            </Typography>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5} mt={1.5}>
            <Typography
              sx={{
                color: '#263238',
                fontWeight: 700,
              }}>
              {content.skuContent.down} {content.skuContent.downText}
            </Typography>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid item xs={5}>
            <Typography
              sx={{
                color: '#F54949',
                fontWeight: 700,
                fontSize: 28,
              }}>
              <span>{skuContent.downPrice}</span>
            </Typography>
          </Grid>

          <Grid xs={1}></Grid>
          <Grid xs={5}>
            <Typography
              sx={{
                color: '#263238',
                fontWeight: 700,
              }}>
              {content.skuContent.installmentsText}
            </Typography>
            <Box>
              <Typography
                component="span"
                sx={{
                  color: '#505050',
                  fontWeight: 700,
                  fontSize: 12,
                }}>
                {skuContent.installmentTerm} {content.skuContent.interrestRate0}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={5}>
            <Typography
              sx={{
                color: '#F54949',
                fontWeight: 700,
                fontSize: 28,
              }}>
              <span>{skuContent.installmentPrice}</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
