import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  regionList: any;
  error: string;
};

const initialState: State = {
  regionList: {
    all: [],
    cj: [],
    td: [],
  },
  error: '',
};

const regionListSlice = createSlice({
  name: 'regionList',
  initialState,
  reducers: {
    clearRegionList: (state) => {
      state.regionList = initialState.regionList;
    },
    saveRegionList: (state, action: PayloadAction<any>) => {
      state.regionList = action.payload;
    },
  },
});

export const { clearRegionList, saveRegionList } = regionListSlice.actions;
export default regionListSlice.reducer;
