import { Box, Typography } from '@mui/material';
import BoxDesktop from '../../commons/ui/box-desktop';
import FAQContent from '../../faqs/faq-content';
import LargeheroContent from './largehero-content';
import bannerServiceDesktop from '../../../assets/images/campaign/banner/service-desktop.png';
import largeheroDesktop from '../../../assets/images/campaign/largehero/largehero-desktop-11042024.png';
import BestSellingContent from './best-selling-content';
import StepContent from './step-content';
import { useNavigate } from 'react-router-dom';
import ButtonContent from './button-content';
import { TypeScreen } from '../../../utils/enum/responsive-enum';
import { content } from '../../../contentData/kline-campaign';
import { platform } from '../../../utils/enum/search-branches-enum';

export default function HomePageContentDesktop() {
  const navigate = useNavigate();

  return (
    <>
      <BoxDesktop>
        <Box mb={4}>
          <Typography component="div" variant="h5" sx={{ fontWeight: 700 }}>
            {content.homepage.lineBK.title}
          </Typography>
        </Box>
        <Box mb={2}>
          <LargeheroContent largehero={largeheroDesktop} type={TypeScreen.DESKTOP} />
        </Box>
        <Box mb={6}>
          <ButtonContent type={TypeScreen.DESKTOP} />
        </Box>

        <Box mb={4}>
          <Typography component="div" variant="h5" sx={{ fontWeight: 700 }}>
            {content.homepage.bestSelling.title}
          </Typography>
        </Box>
        <Box mb={4}>
          <BestSellingContent type={TypeScreen.DESKTOP} />
        </Box>

        <Box mb={4}>
          <Typography component="div" variant="h5" sx={{ fontWeight: 700 }}>
            {content.homepage.step.title}
          </Typography>
        </Box>
        <Box mb={4}>
          <StepContent type={TypeScreen.DESKTOP} />
        </Box>

        <Box sx={{ margin: 'auto', mb: 4 }}>
          <img
            src={bannerServiceDesktop}
            width="100%"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              navigate('/branches-search', { state: { platform: platform.CJ } });
            }}
          />
        </Box>

        <Box mb={2}>
          <Typography component="div" variant="h5" sx={{ fontWeight: 700 }}>
            {content.homepage.faq.title}
          </Typography>
        </Box>

        <Box mb={8}>
          <FAQContent />
        </Box>

        <Box>
          <ButtonContent type={TypeScreen.DESKTOP} />
        </Box>
      </BoxDesktop>
    </>
  );
}
