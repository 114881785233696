import { Typography } from '@mui/material';
import { policyContent } from '../../contentData/policy';
import BoxMobile from '../commons/ui/box-mobile';
import TextContent from './text-content';

interface Props {
  landscape: boolean;
}

export default function CookieContentMobile({ landscape }: Props) {
  return (
    <>
      <BoxMobile>
        <Typography component="div" variant="h5">
          {policyContent.header}
        </Typography>

        <TextContent />
      </BoxMobile>
    </>
  );
}
