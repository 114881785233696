import { Box, Grid, Typography } from '@mui/material';
import PriceComponent from './price-component';
import { content } from './content-data-sku';

export interface props {
  skuContent: any;
}
export default function ApplianceContentBox({ skuContent }: props) {
  return (
    <>
      <Typography component="div" variant="h4" sx={{ color: '#0E7F42', fontWeight: 700 }}>
        {skuContent.title}
      </Typography>

      <Grid container mt={6}>
        <Grid item xs={6} sx={{ textAlign: 'center', pt: 5 }}>
          <Box
            sx={{
              backgroundImage: `url(${skuContent.urlImage})`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: skuContent.imageHeight,
              width: skuContent.imageWidth,
            }}></Box>
          <PriceComponent
            skuContent={skuContent}
            isAppliance={skuContent.isAppliance}
            isAircondition={skuContent.isAircondition}
            isTable={true}
          />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'left' }}>
          <Typography component="div" variant="h4" sx={{ color: '#263238', mt: 2 }}>
            {skuContent.skuName}
          </Typography>

          <Typography component="div" variant="h6" sx={{ color: '#263238' }}>
            {skuContent.skuModel}
          </Typography>

          <Typography component="div" variant="h6" sx={{ color: '#263238', mt: 2 }}>
            <strong> {skuContent.specVal1}</strong> {skuContent.specVal2}
          </Typography>

          <Grid container mt={4}>
            <Grid xs={2}>
              <img src={skuContent.features1.icon} width={60} />
            </Grid>
            <Grid xs={10} pt={3}>
              <strong>{skuContent.features1.title}</strong> {skuContent.features1.description}
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid xs={2} pt={2}>
              <img src={skuContent.features2.icon} width={60} />
            </Grid>
            <Grid xs={10} pt={3}>
              <strong>{skuContent.features2.title}</strong> {skuContent.features2.description}
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid xs={2} pl={1} pt={1}>
              <img src={skuContent.features3.icon} width={50} />
            </Grid>
            <Grid xs={10} pt={3}>
              <strong>{skuContent.features3.title}</strong> {skuContent.features3.description}
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid xs={2} pt={1}>
              <img src={skuContent.features4.icon} width={60} />
            </Grid>
            <Grid xs={10} pt={3}>
              <strong>{skuContent.features4.title}</strong> {skuContent.features4.description}
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography
              component="div"
              sx={{ color: '#6D6D6D', mt: 2, fontSize: 12, fontWeight: 400, textAlign: 'justify' }}>
              {content.skuContent.hypothesis}
            </Typography>
            <Typography component="div" sx={{ color: '#6D6D6D', fontSize: 12, fontWeight: 400, textAlign: 'justify' }}>
              {skuContent.remark}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
