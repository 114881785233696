import { platform } from '../utils/enum/search-branches-enum';

export const content = {
  platform: {
    all: 'ทั้งหมด',
    cj: 'CJ MORE',
    td: 'ถูกดี มีมาตราฐาน',
  },
  productType: {
    all: 'ประเภทสินค้าทั้งหมด',
  },
  fillterMode: {
    platform: platform.CJ,
    isFillter: false,
  },
};
