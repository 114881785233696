import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import _ from 'lodash';
import { useAppSelector } from '../../store/store';

import banner from '../../assets/images/search-branches/banner.png';

import BoxDesktop from '../commons/ui/box-desktop';
import SearchBranchesListDesktop from './search-branches-list-desktop';
import FilterPlatform from './button-platform';
import FilterProductType from './select-product-type';
import FilterProvince from './autocomplete-province';
import FilterDistrict from './autocomplete-district';
import LoadingModal from '../commons/ui/loading-modal';
import { SearchBranches } from '.';
import FilterRegion from './autocomplete-region';
import { useLocation } from 'react-router-dom';
import { platform } from '../../utils/enum/search-branches-enum';
import { content } from '../../contentData/branches';

const btnStyle = {
  fontWeight: 700,
  color: '#FFFFFF',
  width: '45%',
  border: '2px solid #0d7f42',
  background: '#0d7f42',
  borderRadius: '20px',
};

export default function SearchBranchesDeskTop() {
  const { state } = useLocation();

  const saveReadCSVMerchantList = useAppSelector((state) => state.SaveReadCSVMerchantList.merchantList);
  const regionListOptionAll = useAppSelector((state) => state.regionList.regionList.all);
  const regionListOptionCJ = useAppSelector((state) => state.regionList.regionList.cj);
  const regionListOptionTD = useAppSelector((state) => state.regionList.regionList.td);

  const provinceListOptionAll = useAppSelector((state) => state.provinceList.provinceList.all);
  const provinceListOptionCJ = useAppSelector((state) => state.provinceList.provinceList.cj);
  const provinceListOptionTD = useAppSelector((state) => state.provinceList.provinceList.td);

  const districtListOptionAll = useAppSelector((state) => state.districtList.districtList.all);
  const districtListOptionCJ = useAppSelector((state) => state.districtList.districtList.cj);
  const districtListOptionTD = useAppSelector((state) => state.districtList.districtList.td);

  const [region, setRegion] = useState('0');
  const [province, setProvince] = useState('0');
  const [district, setDistrict] = useState('0');
  const [platformSelect, setPlatformSelect] = useState(
    state && state.platform ? state.platform : content.fillterMode.platform ?? ''
  );
  const [merchantName, setMerchantName] = useState('');

  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [productType, setProductType] = useState('');
  const [regionList, setRegionList] = useState<any>();
  const [provinceList, setProvinceList] = useState<any>();
  const [districtList, setDistrictList] = useState<any>([]);
  const [merchantList, setmerchantList] = useState<any>();

  const handleChangeKeySearch = (event: any) => {
    const textVal = event.target.value;
    if (textVal) {
      setMerchantName(textVal);
    } else {
      setMerchantName('');
    }
  };

  const handleSelectPlatform = (value: any) => {
    setPlatformSelect(value);
    setRegion('');
    setProvince('');
    setDistrict('');
    handleSelectItemRegion(null);
    handleSelectItemProvince(null);
    handleSelectItemDistrict(null);
    if (value === '' || value === 'all') {
      setRegionList(regionListOptionAll);
      setProvinceList(provinceListOptionAll);
    } else if (value === platform.CJ) {
      setRegionList(regionListOptionCJ);
      setProvinceList(provinceListOptionCJ);
    } else if (value === platform.TD) {
      setRegionList(regionListOptionTD);
      setProvinceList(provinceListOptionTD);
    }
  };

  const handleSelectItemRegion = (option: any) => {
    handleSelectItemProvince(null);
    handleSelectItemDistrict(null);
    if (option === null) {
      setRegion('');
      setProvinceList(provinceList);
      setDistrictList([]);
    }
    if (option) {
      setRegion(option.regionName);
      let provinceListOptionFilter: any;
      let districtListOptionFilter: any;
      if (platformSelect === platform.CJ) {
        provinceListOptionFilter = _.filter(provinceListOptionCJ, (item: any) => item.regionName === option.regionName);
        districtListOptionFilter = _.filter(districtListOptionCJ, (item: any) => item.regionName === option.regionName);
      } else if (platformSelect === platform.TD) {
        provinceListOptionFilter = _.filter(provinceListOptionTD, (item: any) => item.regionName === option.regionName);
        districtListOptionFilter = _.filter(districtListOptionCJ, (item: any) => item.regionName === option.regionName);
      } else {
        provinceListOptionFilter = _.filter(
          provinceListOptionAll,
          (item: any) => item.regionName === option.regionName
        );
        districtListOptionFilter = _.filter(
          districtListOptionAll,
          (item: any) => item.regionName === option.regionName
        );
      }
      setProvinceList(provinceListOptionFilter);
    }
  };

  const handleSelectItemProvince = async (option: any) => {
    handleSelectItemDistrict(null);
    if (option === null) {
      setProvince('');
      setDistrictList([]);
    }
    if (option) {
      setProvince(option.provinceName);
      let districtListOptionFilter: any;
      if (platformSelect === platform.CJ) {
        districtListOptionFilter = _.filter(
          districtListOptionCJ,
          (item: any) => item.provinceName === option.provinceName
        );
      } else if (platformSelect === platform.TD) {
        districtListOptionFilter = _.filter(
          districtListOptionTD,
          (item: any) => item.provinceName === option.provinceName
        );
      } else {
        districtListOptionFilter = _.filter(
          districtListOptionAll,
          (item: any) => item.provinceName === option.provinceName
        );
      }
      setDistrictList(districtListOptionFilter);
    }
  };

  const handleSelectItemDistrict = (option: any) => {
    if (option === null) setDistrict('');
    if (option) setDistrict(option.districtName);
  };

  const handleSelectProductType = (option: any) => {
    if (option === '0') setProductType('');
    if (option) setProductType(option);
  };

  const handleSearch = () => {
    setOpenLoadingModal(true);
    let merchantListTemp: any = saveReadCSVMerchantList;

    const payload = {
      platform: platformSelect,
      productType: productType,
      merchantName: merchantName,
      region: region,
      province: province,
      district: district,
    };
    setmerchantList(SearchBranches(merchantListTemp, payload));

    setTimeout(() => {
      setOpenLoadingModal(false);
    }, 300);
  };

  return (
    <>
      <BoxDesktop>
        <Box sx={{ maxWidth: 1230, margin: 'auto' }}>
          <Grid container>
            <Grid xs={8}>
              <Typography component="div" variant="h3" sx={{ color: '#0E7F42', fontWeight: 700, mt: 4, ml: 6 }}>
                สาขาและร้านค้าให้บริการ
              </Typography>
            </Grid>
            <Grid xs={4}></Grid>
            <Grid xs={12} p={6}>
              <img src={banner} width="100%" />
            </Grid>
            {content.fillterMode.isFillter && (
              <Grid xs={12}>
                <FilterPlatform
                  defaultPlatform={platformSelect}
                  onClick={(value: any) => handleSelectPlatform(value)}
                />
              </Grid>
            )}
          </Grid>
          <Box mt={1} mb={1} p={6}>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1 }}>
              <Grid item xs={3} pr={3}>
                <FilterProductType onComplete={handleSelectProductType} />
              </Grid>
              <Grid item xs={6} mt={0.4}>
                <TextField
                  id="txtKey"
                  name="key"
                  size="small"
                  value={merchantName || ''}
                  onChange={handleChangeKeySearch}
                  fullWidth
                  placeholder="ค้นหาสาขา/ร้านค้า"
                  autoComplete="off"
                  variant="standard"
                  sx={{ borderBottom: '1px solid #6D6D6D' }}
                />
              </Grid>
              <Grid item xs={2} />
            </Grid>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1 }} mt={3}>
              <Grid item xs={3} pr={3}>
                <FilterRegion
                  regionListOption={regionList ?? regionListOptionAll}
                  onComplete={handleSelectItemRegion}
                />
              </Grid>
              <Grid item xs={3} pr={3}>
                <FilterProvince
                  provinceListOption={provinceList ?? provinceListOptionAll}
                  onComplete={handleSelectItemProvince}
                />
              </Grid>
              <Grid item xs={3}>
                <FilterDistrict districtList={districtList} clear={false} onComplete={handleSelectItemDistrict} />
              </Grid>
              <Grid item xs={2} pl={4}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ ...btnStyle, marginTop: '-10px', width: '155px' }}
                  onClick={handleSearch}>
                  ค้นหา
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box mt={1} pb={2}>
            <Grid container rowSpacing={3} columnSpacing={{ xs: 1 }}>
              <Grid item xs={0.5} />
              <Grid item xs={11}>
                {merchantList && <SearchBranchesListDesktop list={merchantList || []} />}
              </Grid>
              <Grid item xs={0.5} />
            </Grid>
          </Box>
        </Box>
      </BoxDesktop>
      <LoadingModal open={openLoadingModal} />
    </>
  );
}
