import { Box, Link, Typography } from '@mui/material';
import { policyContent } from '../../contentData/policy';
import BoxContent from './box-content';

export default function TextContent() {
  return (
    <>
      <BoxContent title={policyContent.policy.title}>
        {policyContent.policy.content1} (
        <Link href={policyContent.policy.link} target="_blank" sx={{ color: '#0062c2' }}>
          {policyContent.policy.link}
        </Link>
        ) {policyContent.policy.content2}
      </BoxContent>

      <BoxContent title={policyContent.cookies.title}>
        {policyContent.cookies.content1}
        <Typography component="div" variant="body1" sx={{ mt: 2 }}>
          &emsp;&emsp;{policyContent.cookies.content2}{' '}
          <Link href={policyContent.cookies.link} sx={{ color: '#0062c2' }}>
            {policyContent.cookies.link}
          </Link>
        </Typography>
      </BoxContent>

      <BoxContent title={policyContent.kbao.title}>{policyContent.kbao.content1}</BoxContent>

      <Box mt={2}>
        <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
          {policyContent.cookiesType.title}
        </Typography>

        <Box mt={2}>
          <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <td width="40%" align="center" style={{ border: '1px solid #030303' }}>
                  <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {policyContent.cookiesType.header1}
                  </Typography>
                </td>
                <td align="center" style={{ border: '1px solid #030303', borderLeft: 'none' }}>
                  <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
                    {policyContent.cookiesType.header2}
                  </Typography>
                </td>
              </tr>
            </thead>
            <tbody>
              {policyContent.cookiesType.data.map((data) => (
                <tr>
                  <td width="20%" valign="top" style={{ border: '1px solid #030303', borderTop: 'none', padding: 6 }}>
                    {data.title}
                  </td>
                  <td style={{ border: '1px solid #030303', borderLeft: 'none', borderTop: 'none', padding: 6 }}>
                    {data.content}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>

        <BoxContent title={policyContent.manageCookies.title}>
          {policyContent.manageCookies.content1}{' '}
          <Link href={policyContent.manageCookies.link} sx={{ color: '#0062c2' }}>
            {policyContent.manageCookies.link}
          </Link>
        </BoxContent>

        <BoxContent title={policyContent.announcement.title}>{policyContent.announcement.content1}</BoxContent>
      </Box>
    </>
  );
}
