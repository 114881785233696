import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';

import { contentList, header } from '../../contentData/announcement';
import { openFilePdf } from '.';
import LoadingModal from '../commons/ui/loading-modal';

interface Props {
  type: string;
}

export default function Content({ type }: Props) {
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  return (
    <>
      <Box mt={4}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: '#F3F5F8' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 700, fontSize: 16 }}>{header.title}</TableCell>
                <TableCell width={80} sx={{ fontWeight: 700, fontSize: 16 }}>
                  {header.date}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contentList.map((row) => (
                <TableRow key={row.title} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Box
                      id="download-file"
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setOpenLoadingModal(true);
                        openFilePdf(row.pdfFile);

                        setTimeout(() => {
                          setOpenLoadingModal(false);
                        }, 300);
                      }}>
                      {row.title}{' '}
                    </Box>
                  </TableCell>

                  <TableCell>{row.datePublished}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <LoadingModal open={openLoadingModal} />
    </>
  );
}
