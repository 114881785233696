import { Box } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import banner1 from '../../../assets/images/home/jun/m-banner-1.png';
import banner2 from '../../../assets/images/home/jun/m-banner-2.png';
import banner3 from '../../../assets/images/home/jun/m-banner-3.png';

const glassPanel = {
  margin: '5% auto',
};

const images = [banner1, banner2, banner3];
export default function CarousalImageMobile() {
  return (
    <>
      <Box className="slide-container">
        <Slide>
          {images.map((slideImage, index) => (
            <Box key={index} style={glassPanel}>
              <img
                src={slideImage}
                width="100%"
                style={{
                  borderRadius: 4,
                }}
              />
            </Box>
          ))}
        </Slide>
      </Box>
    </>
  );
}
