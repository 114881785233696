import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import { footerContent } from '../../contentData/footer';
import CookieConsentTab from '../commons/ui/cookie-consent';

export default function FooterDesktop() {
  return (
    <>
      <Grid container sx={{ fontSize: 14, fontWeight: 400 }}>
        <Grid item xs={4} p={2} pl={4}>
          <Typography component="div" sx={{ fontSize: 14, fontWeight: 700 }}>
            {footerContent.contactUs.title}
          </Typography>
          {footerContent.contactUs.email.title}{' '}
          <a
            target="_blank"
            href={footerContent.contactUs.email.link}
            style={{ textDecoration: 'none', color: '#FFFFFF' }}>
            {footerContent.contactUs.email.text}
          </a>
          <br />
          {footerContent.line.title}{' '}
          <a target="_blank" href={footerContent.line.link} style={{ textDecoration: 'none', color: '#FFFFFF' }}>
            {footerContent.line.text}
          </a>
        </Grid>
        <Grid item xs={4} p={2}>
          <Grid container>
            <Grid item xs={4}>
              <Typography component="span" sx={{ fontSize: 14, fontWeight: 700 }}>
                {footerContent.businessHours.title}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {footerContent.businessHours.text}
            </Grid>

            <Grid item xs={4}>
              <Typography component="span" sx={{ fontSize: 14, fontWeight: 700 }}>
                {footerContent.customerService.title}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              {footerContent.customerService.text}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} p={2} pr={0}>
          <Link
            to={footerContent.privacy.link}
            style={{ textDecoration: 'none', color: '#FFFFFF', marginRight: '20px' }}>
            {footerContent.privacy.text}
          </Link>
          <Link to={footerContent.cookie.link} style={{ textDecoration: 'none', color: '#FFFFFF' }}>
            {footerContent.cookie.text}
          </Link>

          <Box sx={{ fontSize: 14, mb: '20px' }}>
            {footerContent.license.text} {footerContent.version}
          </Box>
        </Grid>
      </Grid>
      <CookieConsentTab />
    </>
  );
}
