import { Box } from '@mui/material';

export interface Props {
  largehero: string;
  type: string;
}

export default function LargeheroContent(props: Props) {
  return (
    <>
      <Box sx={{ margin: 'auto', mb: 4 }}>
        <img src={props.largehero} width="100%" />
      </Box>
    </>
  );
}
