import { Box, Button, Typography } from '@mui/material';
import Succeed from '../../../assets/images/campaign/succeed.png';
import { useNavigate } from 'react-router-dom';
import { content } from '../../../contentData/kline-campaign';
import { platform } from '../../../utils/enum/search-branches-enum';

export default function SucceedFormBox() {
  const boxStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  };

  const btnStyle = {
    width: 340,
    maxWidth: '100%',
    height: 56,
    fontWeight: 700,
    color: '#FFFFFF',
    border: '2px solid #0B8041',
    borderRadius: 2,
    background: '#0B8041',
    '&:hover': {
      background: '#0B8041',
    },
  };

  const navigate = useNavigate();

  const heightBox = window.innerHeight - 268 || 500;

  return (
    <>
      <Box
        sx={{
          minHeight: heightBox,
          ...boxStyle,
        }}>
        <Box sx={{ display: 'block' }}>
          <Box mt={4}>
            <img src={Succeed} width="250" />
          </Box>

          <Box mt={4}>
            <Typography component="div" sx={{ fontSize: 20, fontWeight: 700 }}>
              {content.form.succeed.title}
            </Typography>
          </Box>
          <Box sx={{ fontSize: 18, whiteSpace: 'pre-line' }}>{content.form.succeed.subTitle}</Box>

          <Box mt={4}>
            <Button
              sx={{ ...btnStyle }}
              onClick={() => {
                navigate('/branches-search', { state: { platform: platform.CJ } });
              }}>
              {content.form.succeed.button.branchesSearch}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
