import { Box } from '@mui/material';
import CookieContent from '../components/cookie/cookie-content';
import { Screen } from '../utils/enum/responsive-enum';

export default function CookiePolicy() {
  return (
    <>
      <Box sx={{ maxWidth: Screen.MAXWIDTH, margin: 'auto' }}>
        <CookieContent />
      </Box>
    </>
  );
}
