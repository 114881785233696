export enum Responsive {
  DESKTOP_OR_LAPTOP = '1224px',
  BIGSCREEN = '1824px',
  TABLE_OR_MOBILE = '1224px',
  PORTRAIT = 'portrait',
  RETINA = '2dppx',
}

export enum TypeScreen {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export enum Screen {
  MAXWIDTH = '1230px',
}

export enum ScreenBox {
  TABLE_OR_MOBILE = 1224,
  MINWIDTH = 500,
}
