import { Box } from '@mui/material';
import FormBox from '../../components/campaign/form/form-box';

export default function KLineForm() {
  return (
    <>
      <Box sx={{ maxWidth: 680, margin: 'auto', mt: '4em', textAlign: 'left', minHeight: '70vh' }}>
        <FormBox />
      </Box>
    </>
  );
}
