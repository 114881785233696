import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Papa from 'papaparse';
import { ReadCSVFile } from '../../utils/utils';

type State = {
  merchantList: [];
  error: string;
};

const initialState: State = {
  merchantList: [],
  error: '',
};

export const fetchReadCSVMerchantListAsync = createAsyncThunk('ReadCSVMerchantList', async () => {
  try {
    let response: any = [];
    const pathFile = '/merchantCJ.csv';
    // response = ReadCSVFile(pathFile);
    // Papa.parse(pathFile, {
    //   header: true,
    //   download: true,
    //   skipEmptyLines: true,
    //   delimiter: ',',
    //   complete: (results: any) => {
    //     console.log(results.data);
    //     return results.data;
    //   },
    //   error: (err: Error) => {
    //     console.log(err);
    //     return err;
    //   },
    // });
    console.log('ReadCSVMerchantList = ', response);
    return response;
  } catch (error) {
    throw error;
  }
});

const ReadCSVMerchantListSlice = createSlice({
  name: 'ReadCSVMerchantList',
  initialState,
  reducers: {
    clearReadCSVMerchantList: (state) => initialState,
  },
  extraReducers: (builer) => {
    builer.addCase(fetchReadCSVMerchantListAsync.fulfilled, (state, action: PayloadAction<any>) => {
      state.merchantList = action.payload;
    });
    builer.addCase(fetchReadCSVMerchantListAsync.rejected, (state) => {
      state.error = 'failed';
    });
  },
});

export const { clearReadCSVMerchantList } = ReadCSVMerchantListSlice.actions;
export default ReadCSVMerchantListSlice.reducer;
