import _ from 'lodash';
import { platform, productType, productTypeStatus } from '../../utils/enum/search-branches-enum';

export const SearchBranches = (merchantList: any, payload: any) => {
  let result = merchantList;
  if (payload.platform && payload.platform !== 'all')
    result = _.filter(result, (item: any) => item.Platform === payload.platform);
  //----------------------------------------------------------------

  if (payload.productType && payload.productType === productType.MOBILE)
    result = _.filter(result, (item: any) => item.Mobile_Phone === productTypeStatus.YES);

  if (payload.productType && payload.productType === productType.ELECTRICAL_APPLIIANCE)
    result = _.filter(result, (item: any) => item.Electrical_Appliance === productTypeStatus.YES);

  if (payload.merchantName)
    result = _.filter(result, function (item) {
      return item.Merchant_Name_Detail.indexOf(payload.merchantName) > -1;
    });

  if (payload.region && payload.region != '0') result = _.filter(result, (item: any) => item.Region === payload.region);
  if (payload.province && payload.province != '0')
    result = _.filter(result, (item: any) => item.Province === payload.province);
  if (payload.district && payload.district != '0')
    result = _.filter(result, (item: any) => item.District === payload.district);
  return result;
};

export const uniqRegionList = (list: any, filterPlatform: string) => {
  let regionList: any = _.uniqBy(list, (item: any) => [item.Region, item.Platform].join()).map((item: any) => ({
    regionName: item.Region,
    platform: item.Platform,
  }));
  regionList = _.sortBy(regionList, (word) => word.regionName.normalize('NFD'));
  if (filterPlatform === platform.CJ || filterPlatform === platform.TD) {
    return _.filter(regionList, (item: any) => item.platform === filterPlatform);
  } else if (filterPlatform === 'all') {
    return _.uniqBy(regionList, (item: any) => item.regionName);
  }
};

export const uniqProvinceList = (list: any, filterPlatform: string) => {
  let provinceList: any = _.uniqBy(list, (item: any) => [item.Province, item.Platform].join()).map((item: any) => ({
    provinceName: item.Province,
    regionName: item.Region,
    platform: item.Platform,
  }));
  provinceList = provinceList.sort((a: any, b: any) => a.provinceName.localeCompare(b.provinceName, 'th'));
  if (filterPlatform === platform.CJ || filterPlatform === platform.TD) {
    return _.filter(provinceList, (item: any) => item.platform === filterPlatform);
  } else if (filterPlatform === 'all') {
    return _.uniqBy(provinceList, (item: any) => item.provinceName);
  }
};

export const uniqDistrictList = (list: any, filterPlatform: string) => {
  let districtList: any = _.uniqBy(list, (item: any) => [item.District, item.Platform].join()).map((item: any) => ({
    districtName: item.District,
    provinceName: item.Province,
    regionName: item.Region,
    platform: item.Platform,
  }));
  districtList = districtList.sort((a: any, b: any) => a.districtName.localeCompare(b.districtName, 'th'));
  if (filterPlatform === platform.CJ || filterPlatform === platform.TD) {
    return _.filter(districtList, (item: any) => item.platform === filterPlatform);
  } else if (filterPlatform === 'all') {
    return _.uniqBy(districtList, (item: any) => item.districtName);
  }
};
