import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import ContactContentDeskTop from './contact-content-desktop';
import ContactContentMobile from './contact-content-mobile';

export default function ContactContent() {
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: ${Responsive.DESKTOP_OR_LAPTOP} )` });
  const isBigScreen = useMediaQuery({ query: `(min-width: ${Responsive.BIGSCREEN})` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {/* {isDesktopOrLaptop && <ContactContentDeskTop />}
      {isBigScreen && <ContactContentDeskTop />} */}

      {!isTabletOrMobile && <ContactContentDeskTop />}
      {isTabletOrMobile && (
        <>{isPortrait ? <ContactContentMobile landscape={true} /> : <ContactContentMobile landscape={false} />}</>
      )}
    </>
  );
}
