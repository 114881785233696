export const footerContent = {
  contactUs: {
    title: 'ติดต่อเรา',
    email: {
      title: 'Email:',
      text: 'info@kasikorncarabao.com',
      link: 'mailto:info@kasikorncarabao.com',
    },
  },
  line: {
    title: 'Line Official:',
    text: '@kbao',
    link: 'https://line.me/R/ti/p/@kbao',
  },
  businessHours: {
    title: 'เวลาทำการปกติ',
    text: 'จันทร์ - ศุกร์ 8:30 - 17:00 น.',
  },
  customerService: {
    title: 'ศูนย์บริการลูกค้า',
    text: 'ทุกวัน 9:30 - 19:00 น.',
  },
  privacy: {
    text: 'ประกาศความเป็นส่วนตัว',
    link: '/privacy-policy',
  },
  cookie: {
    text: 'นโยบายการใช้คุ้กกี้',
    link: '/cookie-policy',
  },
  license: {
    text: 'Kasikorn Carabao Co.,Ltd. All Rights Reserved.',
  },
  version: 'v.1.2.81',
};
