import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import CookieContentDeskTop from './cookie-content-desktop';
import CookieContentMobile from './cookie-content-mobile';

export default function CookieContent() {
  const isDesktopOrLaptop = useMediaQuery({ query: `(min-width: ${Responsive.DESKTOP_OR_LAPTOP} )` });
  const isBigScreen = useMediaQuery({ query: `(min-width: ${Responsive.BIGSCREEN})` });
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {/* {isDesktopOrLaptop && <PolicyContentDeskTop />}
      {isBigScreen && <PolicyContentDeskTop />} */}

      {!isTabletOrMobile && <CookieContentDeskTop />}
      {isTabletOrMobile && (
        <>{isPortrait ? <CookieContentMobile landscape={true} /> : <CookieContentMobile landscape={false} />}</>
      )}
    </>
  );
}
