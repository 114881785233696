import { Box, Typography } from '@mui/material';

export default function TextContent() {
  return (
    <>
      <Box mt={2}>
        <Typography component="div" variant="subtitle1">
          เรียน ท่านลูกค้าที่ใช้บริการ
        </Typography>
        <Typography component="div" variant="body1" mt={4} sx={{ textAlign: 'justify' }}>
          &emsp;&emsp; บริษัท กสิกร คาราบาว จำกัด <b>(“บริษัทฯ”)</b> ให้ความสำคัญกับความเป็นส่วนตัว
          และมุ่งมั่นที่จะคุ้มครองข้อมูล ส่วนบุคคลของท่าน หรือข้อมูลส่วนบุคคลของบุคคลที่มีความเกี่ยวข้องกับธุรกิจของท่าน{' '}
          <b>(“ข้อมูลส่วนบุคคล”)</b> ตามกฎหมายไทย
        </Typography>
      </Box>

      <Box mt={2} ml={6}>
        <Typography component="div" variant="subtitle1">
          ประกาศความเป็นส่วนตัวฉบับนี้อธิบายถึงรายละเอียดดังต่อไปนี้
        </Typography>
        <ul>
          <li style={{ textAlign: 'justify' }}>
            ข้อมูลส่วนบุคคลประเภทใดบ้างที่บริษัทฯ เก็บรวบรวม โดยที่ข้อมูลดังกล่าวรวมถึงข้อมูลที่ท่านมอบให้ แก่บริษัทฯ
            เกี่ยวกับตัวท่านเอง หรือบุคคลที่มีความเกี่ยวข้องกับธุรกิจของท่าน <b>(“ท่าน”)</b> และข้อมูลที่บริษัทฯ
            รับทราบจากการที่ท่านเป็นลูกค้าของบริษัทฯ และข้อมูลทางการตลาดที่ท่านประสงค์ที่จะให้บริษัทฯ ส่งให้ แก่ท่าน
          </li>
          <li>บริษัทฯ ใช้ข้อมูลส่วนบุคคลของท่านอย่างไร</li>
          <li>บริษัทฯ เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลใดบ้าง</li>
          <li>
            ทางเลือกที่บริษัทฯ นำเสนอให้แก่ท่าน รวมถึงวิธีการเข้าถึง
            และการดำเนินการให้ข้อมูลส่วนบุคคลของท่านเป็นปัจจุบัน
          </li>
          <li>สิทธิที่ท่านมีต่อข้อมูลส่วนบุคคลของท่านได้แก่อะไรบ้าง และกฎหมายนั้นปกป้องคุ้มครองท่านอย่างไร</li>
        </ul>

        <Box mt={2} sx={{ paddingLeft: '1em' }}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            1. <u style={{ marginLeft: '1em' }}>ข้อมูลส่วนบุคคลประเภทใดบ้างที่บริษัทฯ เก็บรวบรวม</u>
          </Typography>
          <Typography component="div" variant="body1" mt={2} sx={{ textAlign: 'justify' }}>
            บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลหลากหลายประเภท โดยประเภทของข้อมูลส่วนบุคคลที่บริษัทฯ
            เก็บรวบรวมนั้นขึ้นอยู่กับแต่ละสถานการณ์ที่เกี่ยวกับการจัดหาผลิตภัณฑ์ทางการเงิน บริการ และ/หรือ การชำระเงิน
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ paddingLeft: '1em' }}>
            บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากแหล่งข้อมูลที่หลากหลายดังต่อไปนี้
          </Typography>

          <ul>
            <li>เมื่อท่านสมัครใช้ผลิตภัณฑ์ทางการเงิน และ/หรือ บริการของบริษัทฯ</li>
            <li style={{ textAlign: 'justify' }}>
              บทสนทนาทางโทรศัพท์ระหว่างท่านกับบริษัทฯ รวมถึงการบันทึกการสนทนาทางโทรศัพท์ ไปรษณีย์ อีเมล์ บันทึกข้อความ
              หรือวิธีการอื่นใด
            </li>
            <li style={{ textAlign: 'justify' }}>
              เมื่อท่านเข้าเยี่ยมชมเว็บไซต์ หรือใช้แอพพลิเคชั่นของบริษัทฯ ผ่านทางมือถือ ซึ่งรวมถึงคุกกี้
              และซอฟต์แวร์ทางอินเทอร์เน็ตอื่นๆ เพื่อเก็บรวบรวมข้อมูลส่วนบุคคล
              โปรดดูข้อมูลเพิ่มเติมผ่านทางนโยบายสำหรับคุกกี้ของบริษัทฯ
            </li>
            <li>การตรวจสอบ และคำชี้แจงใดๆ ที่เกี่ยวกับการเงิน</li>
            <li>แบบสำรวจลูกค้า</li>
            <li>เมื่อท่านเข้าร่วมกิจกรรมส่งเสริมการขายกับทางบริษัทฯ</li>
            <li>
              เมื่อท่านทำให้ข้อมูลส่วนบุคคลของท่านปรากฎแก่สาธารณะอย่างชัดแจ้ง รวมถึงเปิดเผยข้อมูลผ่านทางโซเชียลมีเดีย
              (social media) ในกรณีดังกล่าว บริษัทฯ
              จะเลือกเก็บรวบรวมเฉพาะข้อมูลส่วนบุคคลที่ท่านเลือกให้ปรากฎต่อสาธารณะเท่านั้น
            </li>
            <li>
              เมื่อบริษัทฯ ได้รับข้อมูลส่วนบุคคลของท่านจากบุคคลที่สาม เช่น นายจ้างของท่าน ลูกค้าของบริษัทฯ
              หน่วยงานที่เก็บรวบรวมข้อมูลเครดิต เจ้าหน้าที่ของรัฐที่มีอำนาจ หรือหน่วยงานของรัฐอื่นใด เป็นต้น
            </li>
            <li>เมื่อท่านซสมัครผลิตภัณฑ์ทางการเงิน หรือบริการใดๆ ของบริษัทฯ ผ่านทางบุคคลที่สาม</li>
          </ul>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="body1" sx={{ textAlign: 'justify' }}>
            &emsp;ในบางกรณี บริษัทฯ
            อาจว่าจ้างบุคคลภายนอกที่ไม่ใช่บริษัทในกลุ่มเพื่อเก็บรวบรวมข้อมูลส่วนบุคคลเกี่ยวกับกิจกรรมทางออนไลน์ของท่านเมื่อท่านเข้าเยี่ยมชมแหล่งข้อมูลออนไลน์ของบริษัทฯ
            นอกจากนี้ บริษัทฯ
            ยังอาจใช้ข้อมูลส่วนบุคคลที่รวบรวมจากเว็บไซต์ที่ไม่ใช่ของบริษัทในกลุ่มเพื่อวัตถุประสงค์ในการโฆษณาที่เกี่ยวข้องกับพฤติกรรมการเข้าชมของท่าน
            ทั้งนี้ หากบริษัทฯ ได้ดำเนินการดังกล่าวนี้ บริษัทฯ จะแจ้งเตือน
            และเสนอทางเลือกที่เหมาะสมเพื่อให้ท่านสามารถยกเลิกการประมวลผลดังกล่าวได้
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ paddingLeft: '1em' }}>
            ประเภทของข้อมูลส่วนบุคคลที่บริษัทฯ ประมวลผลภายใต้กฎหมายที่เกี่ยวข้องมีดังต่อไปนี้
          </Typography>

          <ul>
            <li>
              <b>ข้อมูลส่วนตัว:</b> ชื่อ นามสกุล เพศ วันเดือนปีเกิด สถานภาพการสมรส หมายเลขบัตรประจำตัวประชาชน
              หมายเลขอื่น ๆ รวมถึงข้อมูลส่วนบุคคลที่ปรากฏอยู่บนเอกสารที่รัฐออกให้เพื่อใช้ในการยืนยันตัวตน
              หมายเลขประจำตัวผู้เสียภาษี สัญชาติ ลายมือชื่อ ข้อมูลการยืนยันตัวตน (เช่น รหัสผ่าน นามสกุลเดิมของมารดา PINs
              ข้อมูลการจดจำใบหน้า และเสียง) ภาพถ่าย และภาพจากกล้องวงจรปิด (CCTV)
            </li>
            <li>
              <b>ข้อมูลการติดต่อ:</b> ที่อยู่ หมายเลขโทรศัพท์ อีเมล์ และรายละเอียดโปรไฟล์ในช่องทางโซเชียลมีเดีย (social
              media)
            </li>
            <li>
              <b>ข้อมูลการศึกษา:</b> รายละเอียดเกี่ยวกับการศึกษา และคุณสมบัติของท่าน
            </li>
            <li>
              <b>ข้อมูลทางการเงิน:</b> ที่อยู่สำหรับการเรียกเก็บเงิน บันทึกคำสั่ง รายละเอียดธุรกรรม
              และรายละเอียดคู่สัญญา
            </li>
            <li>
              <b>ข้อมูลอิเล็กทรอนิกส์:</b> ที่อยู่ IP คุกกี้ บันทึกกิจกรรม ข้อมูลระบุตัวตน ตัวระบุออนไลน์
              ค่าเอกลักษณ์ประจำอุปกรณ์ (unique device identifiers) และข้อมูลตำแหน่งทางภูมิศาสตร์
            </li>
          </ul>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            2. <u style={{ marginLeft: '1em' }}>บริษัทฯ ใช้ข้อมูลส่วนบุคคลของท่านอย่างไร</u>
          </Typography>
          <Typography component="div" variant="body1" mt={2}>
            &emsp;&emsp;บริษัทฯ อาจเก็บรวบรวม และใช้ข้อมูลส่วนบุคคลของท่านเฉพาะในกรณีที่บริษัทฯ
            มีเหตุผลที่เหมาะสมในการดำเนินการเช่นว่านั้น ทั้งนี้ รวมถึงการเปิดเผยข้อมูลส่วนบุคคลให้กับบุคคลภายนอกด้วย
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ paddingLeft: '1em' }}>
            บริษัทฯ จะใช้ฐานทางกฎหมายอย่างน้อยหนึ่งฐานต่อไปนี้ในการประมวลผล หรือเก็บข้อมูลส่วนบุคคล
          </Typography>

          <ul>
            <li>เมื่อเป็นการปฏิบัติตามข้อตกลงในสัญญาที่บริษัทฯ ทำไว้กับท่าน (ฐานสัญญา)</li>
            <li>เมื่อเป็นหน้าที่ตามกฎหมายของบริษัทฯ (ฐานหน้าที่ตามกฎหมาย)</li>
            <li>เมื่อเป็นผลประโยชน์อันชอบธรรมของบริษัทฯ (ฐานประโยชน์อันชอบธรรม) และ/หรือ</li>
            <li>เมื่อท่านให้ความยินยอม (ฐานความยินยอม)</li>
          </ul>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1">
            &emsp;วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคลของท่านดังต่อไปนี้ เป็นไปตามกฎหมาย และฐานทางกฎหมายที่บริษัทฯ
            อาจเลือกใช้
          </Typography>
        </Box>

        <Box mt={2}>
          <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
            <thead>
              <tr style={{ backgroundColor: '#e7e6e6' }}>
                <td width="60%" align="center" style={{ border: '1px solid #030303', padding: 10 }}>
                  <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
                    วัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคล
                  </Typography>
                </td>
                <td align="center" style={{ border: '1px solid #030303', borderLeft: 'none' }}>
                  <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
                    ฐานในการประมวลผลข้อมูลส่วนบุคคล
                  </Typography>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  width="20%"
                  valign="top"
                  colSpan={2}
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px', fontWeight: 700 }}>
                  ผลิตภัณฑ์ และการบริการ
                </td>
              </tr>
              <tr>
                <td
                  width="20%"
                  valign="top"
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>เพื่อให้บริการ และ/หรือ ผลิตภัณฑ์ของบริษัทฯ</li>
                    <li>เพื่อดำเนินการเกี่ยวกับความสัมพันธ์ของบริษัทฯ กับท่าน หรือ ธุรกิจของท่าน</li>
                    <li>เพื่อศึกษาวิธีการใช้ผลิตภัณฑ์ และ/หรือ บริการจากเรา และ องค์กรอื่นๆ</li>
                    <li>เพื่อวิเคราะห์ข้อมูลผลิตภัณฑ์ และ/หรือ บริการของเราที่ท่านอาจสนใจ</li>
                    <li>เพื่อติดต่อสื่อสารกับท่านเกี่ยวกับผลิตภัณฑ์ และ/หรือ บริการของบริษัทฯ</li>
                    <li>เพื่ออำนวยความสะดวกเกี่ยวกับบริการประกันภัย และผลิตภัณฑ์ทางการเงิน</li>
                    <li>เพื่อพัฒนาผลิตภัณฑ์ และบริการ</li>
                  </ul>
                </td>
                <td
                  valign="top"
                  style={{ border: '1px solid #030303', borderLeft: 'none', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>ฐานสัญญา</li>
                    <li>ฐานประโยชน์อันชอบธรรม</li>
                    <li>ฐานความยินยอม</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td
                  width="20%"
                  valign="top"
                  colSpan={2}
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px', fontWeight: 700 }}>
                  การสนับสนุนลูกค้า
                </td>
              </tr>
              <tr>
                <td
                  width="20%"
                  valign="top"
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>เพื่อจัดทำ และจัดการการชำระเงินของลูกค้า</li>
                    <li>เพื่อเรียกเก็บหนี้</li>
                    <li>เพื่อจัดการ และจัดหาผลิตภัณฑ์</li>
                  </ul>
                </td>
                <td
                  valign="top"
                  style={{ border: '1px solid #030303', borderLeft: 'none', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>ฐานสัญญา</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td
                  width="20%"
                  valign="top"
                  colSpan={2}
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px', fontWeight: 700 }}>
                  การพัฒนาธุรกิจ
                </td>
              </tr>
              <tr>
                <td
                  width="20%"
                  valign="top"
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>เพื่อระบุประเด็นปัญหาที่เกี่ยวกับผลิตภัณฑ์ และบริการในปัจจุบัน</li>
                    <li>เพื่อวางแผนปรับปรุงผลิตภัณฑ์ และบริการในปัจจุบัน</li>
                    <li>เพื่อพัฒนาแนวทางใหม่ๆ โดยมุ่งที่จะตอบสนองความต้องการของลูกค้า และเพื่อขยายธุรกิจของบริษัทฯ</li>
                    <li>เพื่อทดสอบ วิเคราะห์ พัฒนา และ/หรือ ทำให้เกิดผลิตภัณฑ์ และบริการใหม่ๆ</li>
                    <li>
                      เพื่อทำความเข้าใจ และวิเคราะห์ความต้องการ และความพึงพอใจของท่าน เพื่อปกป้องข้อมูลส่วนบุคคลของท่าน
                    </li>
                  </ul>
                </td>
                <td
                  valign="top"
                  style={{ border: '1px solid #030303', borderLeft: 'none', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>ฐานสัญญา</li>
                    <li>ฐานประโยชน์อันชอบธรรม</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td
                  width="20%"
                  valign="top"
                  colSpan={2}
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px', fontWeight: 700 }}>
                  ความปลอดภัย และการบริหารความเสี่ยง
                </td>
              </tr>
              <tr>
                <td
                  width="20%"
                  valign="top"
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>เพื่อสืบสวน รายงาน และป้องกันอาชญากรรมทางการเงิน</li>
                    <li>เพื่อการบริหารความเสี่ยงของบริษัทฯ และลูกค้า</li>
                    <li>เพื่อปฏิบัติตามกฎหมาย และข้อบังคับที่บังคับใช้กับเรา</li>
                    <li>เพื่อตอบสนองต่อข้อร้องเรียน และหาแนวทางทางแก้ไข</li>
                  </ul>
                </td>
                <td
                  valign="top"
                  style={{ border: '1px solid #030303', borderLeft: 'none', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>ฐานหน้าที่ตามกฎหมาย</li>
                    <li>ฐานประโยชน์อันชอบธรรม</li>
                    <li>ฐานสัญญา</li>
                  </ul>
                </td>
              </tr>

              <tr>
                <td
                  width="20%"
                  valign="top"
                  colSpan={2}
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px', fontWeight: 700 }}>
                  การตลาด
                </td>
              </tr>
              <tr>
                <td
                  width="20%"
                  valign="top"
                  style={{ border: '1px solid #030303', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>เพื่อพัฒนา และดำเนินการต่างๆ ที่เกี่ยวกับการตลาด</li>
                    <li>
                      เพื่อติดต่อสื่อสารกับท่านผ่านช่องทางต่างๆ รวมถึงทางอีเมล์ โทรศัพท์ ข้อความ โซเชียลมีเดีย (Social
                      Media) ไปรษณีย์ หรือติดต่อด้วยตนเอง
                      เพื่อให้แน่ใจว่าการสื่อสารดังกล่าวกับท่านเป็นไปตามกฎหมายที่เกี่ยวข้อง
                    </li>
                    <li>เพื่อเก็บรักษา และทำให้ข้อมูลการติดต่อของท่านเป็นปัจจุบันตามความเหมาะสม</li>
                  </ul>
                </td>
                <td
                  valign="top"
                  style={{ border: '1px solid #030303', borderLeft: 'none', borderTop: 'none', padding: '0px 6px' }}>
                  <ul>
                    <li>ฐานประโยชน์อันชอบธรรม</li>
                    <li>ฐานความยินยอม</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="body1" sx={{ textAlign: 'justify' }}>
            &emsp;&emsp;ในกรณีที่บริษัทฯ อาศัยฐานผลประโยชน์อันชอบธรรมของบริษัทฯ ในการประมวลผลข้อมูลส่วนบุคคล บริษัทฯ
            จะพิจารณาว่า ประโยชน์ดังกล่าวของบริษัทฯ นั้น มีความสำคัญยิ่งกว่าสิทธิของท่าน หรือไม่
            และจะต้องสามารถสรุปได้ว่าประโยชน์ดังกล่าวมีความสำคัญยิ่งกว่าสิทธิของท่าน
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            3. <u style={{ marginLeft: '1em' }}>บริษัทฯ เปิดเผยข้อมูลส่วนบุคคลของท่านให้แก่บุคคลใดบ้าง</u>
          </Typography>
          <Typography component="div" variant="body1">
            &emsp;&emsp;บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลอื่นในกรณีที่สามารถทำได้ตามกฎหมาย
            รวมถึงกรณีดังต่อไปนี้ที่บริษัทฯ หรือบุคคลอื่นดังกล่าว
          </Typography>

          <Box sx={{ paddingLeft: 2 }}>
            <ul>
              <li>
                จำเป็นต้องดำเนินการให้แก่ท่านตามข้อตกลงภายใต้สัญญา ผลิตภัณฑ์ หรือบริการที่ท่านร้องขอ (เช่น
                ทำตามคำขอชำระเงิน เป็นต้น)
              </li>
              <li>
                มีหน้าที่ตามกฎหมายในการกระทำการดังกล่าว (เช่น เพื่อสืบหา และป้องกันการทุจริต การหลบหลีกภาษี
                และอาชญากรรมทางการเงิน เป็นต้น)
              </li>
              <li>จำเป็นต้องรายงานตามกฎหมาย ดำเนินคดี ใช้สิทธิตามกฎหมาย หรือปกป้องสิทธิเรียกร้องตามกฎหมาย</li>
              <li>
                กระทำไปเพื่อประโยชน์ของธุรกิจโดยชอบด้วยกฎหมาย (เช่น เพื่อบริหารความเสี่ยง ยืนยันตัวตน
                ให้บริษัทอื่นสามารถให้บริการตามที่ท่านร้องขอได้ หรือประเมินความเหมาะสมของท่านต่อผลิตภัณฑ์ทางการเงิน
                และ/หรือ บริการ เป็นต้น) และ/หรือ
              </li>
              <li>ขอความยินยอมจากท่านในการเปิดเผยข้อมูลดังกล่าว และท่านให้ความยินยอม</li>
            </ul>

            <Typography component="div" variant="body1">
              &emsp; บริษัทฯ อาจเปิดเผยข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ที่กล่าวข้างต้นให้กับบุคคลอื่นดังต่อไปนี้
            </Typography>
            <ul>
              <li>
                บริษัทอื่นๆ ในกลุ่มของบริษัทฯ และผู้รับเหมาช่วง ตัวแทน หรือผู้ให้บริการที่ทำงานให้บริษัทฯ
                หรือให้บริการแก่บริษัทฯ หรือแก่บริษัทอื่นๆ ในกลุ่มของบริษัทฯ รวมถึงพนักงาน ผู้รับเหมาช่วง ผู้ให้บริการ
                กรรมการ และเจ้าหน้าที่ของผู้ให้บริการดังกล่าวนี้ด้วย
              </li>
              <li>
                ผู้ได้รับมอบหมายให้จัดการดูแลทรัพย์สิน หรือผลประโยชน์ เจ้าพนักงานพิทักษ์ทรัพย์ หรือเจ้าพนักงานบังคับคดี
              </li>
              <li>บุคคลที่ค้ำประกัน หรือวางหลักทรัพย์อื่นๆ ตามจำนวนที่ท่านมีภาระต้องชำระให้กับบริษัทฯ</li>
              <li>บุคคลใดๆ ที่ท่านได้ชำระเงินให้ และได้รับการชำระเงิน</li>
              <li>
                บุคคล หรือบริษัทใดๆ ซึ่งเกี่ยวข้องกับการปรับโครงสร้างบริษัท การควบรวม หรือเข้าถือครองกิจการ
                รวมถึงการโอนสิทธิ หรือหน้าที่ใดๆ ซึ่งบริษัทฯ มีอยู่ภายใต้สัญญาระหว่างบริษัทฯ และท่าน
              </li>
              <li>
                หน่วยงานที่บังคับใช้กฎหมาย รัฐบาล ศาล หน่วยงานระงับข้อพิพาท ผู้กำกับดูแลบริษัทฯ ผู้ตรวจสอบบัญชี
                หน่วยงานทางภาษี สมาคมการค้า และบุคคลใดๆ ซึ่งได้รับการแต่งตั้ง หรือร้องขอโดยผู้กำกับดูแลบริษัทฯ
                ให้ตรวจสอบกิจกรรมของบริษัทฯ
              </li>
              <li>
                บุคคลอื่นใดซึ่งมีความเกี่ยวข้องกับข้อพิพาทใดๆ ที่เกิดขึ้น รวมถึงข้อพิพาทที่เกี่ยวข้องกับการทำธุรกรรม
              </li>
              <li>
                หน่วยงานป้องกันการทุจริตซึ่งใช้ข้อมูลดังกล่าวเพื่อสืบหา และป้องกันการทุจริต และอาชญากรรมทางการเงินอื่น ๆ
                และเพื่อยืนยันตัวตนของท่าน
              </li>
              <li>
                บุคคลใดๆ ที่ออกคำสั่ง หรือบริหารจัดการบัญชี ผลิตภัณฑ์ทางการเงิน หรือบริการของท่าน/ในนามของท่าน เช่น
                ผู้รับมอบอำนาจ ทนายความ เป็นต้น และ/หรือ
              </li>
              <li>บุคคลใดๆ ที่บริษัทฯ ได้รับคำสั่งจากท่านให้เปิดเผยข้อมูลส่วนบุคคลของท่านให้กับบุคคลดังกล่าว</li>
            </ul>

            <Typography component="div" variant="body1" sx={{ textAlign: 'justify' }}>
              &emsp; เว้นแต่ได้กำหนดไว้ในประกาศความเป็นส่วนตัวนี้ บริษัทฯ
              จะไม่ใช้ข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์อื่นนอกเหนือจากวัตถุประสงค์ตามที่ระบุไว้ในประกาศความเป็นส่วนตัวนี้
              หากบริษัทฯ จะเก็บรวบรวม ใช้ หรือส่งข้อมูลเพิ่มเติม
              ซึ่งเป็นข้อมูลที่ไม่ได้ระบุไว้ในประกาศความเป็นส่วนตัวนี้ บริษัทฯ จะแจ้งให้ท่านทราบ
              และขอความยินยอมจากท่านก่อนการเก็บรวบรวม ใช้ และเปิดเผย เว้นแต่ในกรณีที่กฎหมายอนุญาตให้บริษัทฯ
              ดำเนินการดังกล่าวได้โดยไม่ต้องอาศัยความยินยอมของท่าน ท่านมีสิทธิให้ความยินยอม หรือปฏิเสธการเก็บรวบรวม ใช้
              และ/หรือ ส่งข้อมูลส่วนบุคคลของท่าน
            </Typography>

            <Typography component="div" variant="body1" mt={2} sx={{ textAlign: 'justify' }}>
              &emsp; บริษัทฯ จะปฏิบัติตามประกาศความเป็นส่วนตัวนี้อย่างเคร่งครัดต่อข้อมูลที่อยู่ในความครอบครองของบริษัทฯ
              ซึ่งเกี่ยวข้องกับลูกค้า และนักลงทุนในอนาคต ปัจจุบัน และในอดีต
            </Typography>

            <Typography component="div" variant="subtitle1" sx={{ mt: 4, fontWeight: 700 }}>
              การส่ง หรือโอนข้อมูลไปยังต่างประเทศ
            </Typography>
            <Typography component="div" variant="subtitle1" sx={{ mt: 1 }}>
              &emsp; ข้อมูลส่วนบุคคลของท่านอาจถูกโอนไปยังต่างประเทศ และถูกเก็บ/ประมวลผลในต่างประเทศ
            </Typography>

            <Typography component="div" variant="subtitle1" sx={{ mt: 1, textAlign: 'justify' }}>
              &emsp; ทั้งนี้ ประเทศเช่นว่านั้นอาจไม่มีมาตรการการคุ้มครองข้อมูลส่วนบุคคลในระดับที่เทียบเท่ากับประเทศไทย
              ในกรณีที่บริษัทฯ จะกระทำการดังกล่าว บริษัทฯ รับรองว่า
              ประเทศดังกล่าวมีมาตรการการคุ้มครองข้อมูลส่วนบุคคลในระดับที่เหมาะสม และการส่ง
              หรือโอนข้อมูลดังกล่าวชอบด้วยกฎหมาย บริษัทฯ อาจต้องส่ง
              หรือโอนข้อมูลส่วนบุคคลเพื่อการปฏิบัติตามสัญญาที่ทำขึ้นระหว่างท่าน และบริษัทฯ
              เพื่อปฏิบัติตามข้อกำหนดทางกฎหมาย เพื่อปกป้องคุ้มครองประโยชน์สาธารณะ และ/หรือ
              เพื่อผลประโยชน์อันชอบธรรมของบริษัทฯ อย่างไรก็ตาม กฎหมายของบางประเทศอาจกำหนดให้บริษัทฯ
              ต้องเปิดเผยข้อมูลส่วนบุคคลบางประเภท (เช่น เปิดเผยให้กับหน่วยงานทางภาษี หรือธนาคารแห่งชาติ)
              ในกรณีเช่นว่านั้น บริษัทฯ จะเปิดเผยข้อมูลส่วนบุคคลให้กับบุคคลที่มีสิทธิเห็น
              หรือเข้าถึงข้อมูลดังกล่าวเท่านั้น
            </Typography>
          </Box>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            4. <u style={{ marginLeft: '1em' }}>การเก็บรักษาข้อมูลส่วนบุคคล</u>
          </Typography>
          <Typography component="div" variant="body1">
            &emsp;&emsp;บริษัทฯ
            จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านไว้ตราบเท่าที่จำเป็นเพื่อให้บรรลุวัตถุประสงค์ในการเก็บรวบรวมข้อมูลดังกล่าวเท่านั้น
            เช่น เพื่อวัตถุประสงค์เกี่ยวกับธุรกิจ และกฎหมาย หรือเพื่อปฏิบัติตามกฎหมายที่เกี่ยวข้อง
          </Typography>

          <Typography component="div" variant="body1" mt={1} sx={{ textAlign: 'justify' }}>
            &emsp;&emsp;บริษัทฯ จะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้เป็นเวลา 10
            ปีภายหลังจากที่ท่านไม่ได้เป็นลูกค้าของบริษัทฯ แล้ว ทั้งนี้ เพื่อประโยชน์ของบริษัทฯ
            ในการดำเนินข้อพิพาททางสัญญาใดๆ ที่อาจเกิดขึ้นนั้นในระยะเวลาดังกล่าว เว้นแต่กรณีที่มีเหตุผลทางกฎหมาย
            หรือเหตุผลทางเทคนิครองรับ บริษัทฯ อาจเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ นานกว่า 10 ปีได้ หากบริษัทฯ
            ไม่มีความจำเป็นที่จะต้องเก็บรักษาข้อมูลของท่านแล้ว บริษัทฯ จะทำลาย ลบ
            หรือทำให้ข้อมูลส่วนบุคคลเป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูลส่วนบุคคล
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            5. <u style={{ marginLeft: '1em' }}>ความถูกต้องของข้อมูลส่วนบุคคล</u>
          </Typography>
          <Typography component="div" variant="body1">
            &emsp;&emsp;ท่านจะต้องทำให้ข้อมูลส่วนบุคคลของท่านที่อยู่ในความครอบครองของบริษัทฯ มีความเป็นปัจจุบัน สมบูรณ์
            และถูกต้อง โดยจะต้องแจ้งบริษัทฯ เมื่อข้อมูลส่วนบุคคลของท่านมีการเปลี่ยนแปลง โดย
          </Typography>

          <Box mt={2} sx={{ border: '1px solid #999999' }}>
            <ul>
              <li>Line Official Account: @kbao</li>
              <li>
                เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล <br />
                <div style={{ whiteSpace: 'pre-line' }}>Email: dataprotectionofficer@kasikorncarabao.com</div>
              </li>
            </ul>
          </Box>

          <Typography component="div" variant="body1" mt={1}>
            &emsp;&emsp;บริษัทฯ จะขอให้ท่านปรับปรุงแก้ไขข้อมูลให้เป็นปัจจุบันเป็นครั้งคราว
            เพื่อให้ข้อมูลดังกล่าวเป็นข้อมูลที่ เป็นปัจจุบัน ถูกต้อง และสมบูรณ์
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            6.
            <u style={{ marginLeft: '1em' }}>
              สิทธิที่ท่านมีต่อข้อมูลส่วนบุคคลของท่านได้แก่อะไรบ้าง และกฎหมายนั้นปกป้องคุ้มครองท่านอย่างไร
            </u>
          </Typography>

          <ul>
            <li style={{ textAlign: 'justify', marginBottom: 4 }}>
              <b>สิทธิขอเพิกถอนความยินยอม:</b> ท่านมีสิทธิที่จะเพิกถอนความยินยอมที่จะให้บริษัทฯ
              ประมวลผลข้อมูลส่วนบุคคลของท่านได้ตลอดเวลา ทั้งนี้ บริษัทฯ อาจประมวลผลข้อมูลส่วนบุคคลของท่านต่อไป
              หากบริษัทฯ มีฐานทางกฎหมายอื่นในการประมวลผลข้อมูลส่วนบุคคลของท่าน
            </li>
            <li style={{ textAlign: 'justify', marginBottom: 4 }}>
              <b>สิทธิในการขอแก้ไขข้อมูลส่วนบุคคล:</b> ท่านมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลที่ไม่สมบูรณ์
              หรือไม่ถูกต้องของท่าน
            </li>
            <li style={{ textAlign: 'justify', marginBottom: 4 }}>
              <b>สิทธิขอให้ส่งหรือโอนข้อมูลส่วนบุคคลไปยังผู้ควบคุมข้อมูลส่วนบุคคลอื่น:</b> ในบางกรณี
              ท่านสามารถขอรับสำเนาข้อมูลส่วนบุคคลของท่านที่สามารถใช้งานโดยทั่วไปได้ในรูปแบบอิเล็กทรอนิกส์
              สิทธิดังกล่าวนี้จะใช้ได้เฉพาะในกรณีของข้อมูลส่วนบุคคลที่ท่านส่งมอบให้แก่บริษัทฯ
              และการประมวลผลข้อมูลส่วนบุคคลดังกล่าวได้กระทำโดยอาศัยความยินยอมของท่าน
              หรือในกรณีที่ข้อมูลส่วนบุคคลดังกล่าวจำเป็นต้องได้รับการประมวลผลเพื่อให้สามารถปฏิบัติตามภาระข้อผูกพันภายใต้สัญญาได้
            </li>
            <li style={{ textAlign: 'justify', marginBottom: 4 }}>
              <b>สิทธิในการขอเข้าถึงข้อมูลส่วนบุคคล:</b> ท่านมีสิทธิในการขอสำเนาข้อมูลส่วนบุคคลของท่านที่บริษัทฯ
              มีไว้ในครอบครอง และมีสิทธิตรวจสอบว่าบริษัทฯ ประมวลผลข้อมูลส่วนบุคคลดังกล่าวตามกฎหมาย หรือไม่
            </li>
            <li style={{ textAlign: 'justify', marginBottom: 4 }}>
              <b>สิทธิในการขอคัดค้านการประมวลผล:</b> ท่านมีสิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านที่บริษัทฯ
              ประมวลผลภายใต้ฐานผลประโยชน์อันชอบธรรมของบริษัทฯ นอกจากนี้
              ท่านยังมีสิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่าน หากบริษัทฯ
              ประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ทางการตลาด และเกี่ยวกับการรวบรวมข้อมูลที่เป็นประโยชน์
              (Profiling) เพื่อการทำการตลาดแบบตรง (การประมวลผลข้อมูลส่วนบุคคลของท่านแบบอัตโนมัติ เพื่อช่วยให้บริษัทฯ
              สามารถประเมินสิ่งต่างๆ เกี่ยวกับท่านได้ เช่น ความต้องการ และความสนใจของท่าน)
            </li>
            <li style={{ textAlign: 'justify', marginBottom: 4 }}>
              <b>สิทธิในการขอระงับการใช้ข้อมูลส่วนบุคคล:</b> ท่านมีสิทธิขอให้บริษัทฯ
              ระงับการประมวลผลข้อมูลส่วนบุคคลของท่านชั่วคราว เช่น เมื่อท่านต้องการให้บริษัทฯ
              แก้ไขข้อมูลส่วนบุคคลของท่านให้ถูกต้อง หรือ เมื่อท่านร้องขอให้บริษัทฯ พิสูจน์เหตุผล
              หรือฐานทางกฎหมายในการประมวลผลข้อมูลส่วนบุคคลของท่า
            </li>
            <li style={{ textAlign: 'justify', marginBottom: 4 }}>
              <b>สิทธิในการขอลบหรือทำลายข้อมูลส่วนบุคคล:</b> ท่านมีสิทธิร้องขอให้บริษัทฯ ลบ ทำลาย
              หรือทำให้ข้อมูลส่วนบุคคลของท่านไม่สามารถระบุตัวตนของท่านได้ในกรณีที่ไม่มีเหตุผลอันสมควรให้บริษัทฯ
              ประมวลผลข้อมูลส่วนบุคคลของท่านต่อไป โดยท่านสามารถใช้สิทธิขอให้บริษัทฯ
              ลบข้อมูลส่วนบุคคลนี้ควบคู่ไปกับสิทธิในการคัดค้านในข้อถัดไป
            </li>
          </ul>

          <Typography component="div" variant="body1" mt={1} sx={{ fontWeight: 700 }}>
            การจัดการกับเรื่องร้องเรียน
          </Typography>

          <Typography component="div" variant="body1" mt={1} sx={{ textAlign: 'justify' }}>
            &emsp;&emsp;&emsp;ท่านสามารถติดต่อบริษัทฯ เพื่อร้องเรียนเกี่ยวกับวิธีการที่บริษัทฯ
            ประมวลผลข้อมูลส่วนบุคคลของท่านได้ และบริษัทฯ จะพิจารณาคำขอของท่านโดยเร็วที่สุด ทั้งนี้
            การเรื่องร้องเรียนต่อบริษัทฯ นี้ ไม่มีผลกระทบต่อสิทธิของท่านในการร้องเรียนต่อเจ้าหน้าที่รัฐ
            หรือคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            7. <u style={{ marginLeft: '1em' }}>ความปลอดภัยของข้อมูลส่วนบุคคลของท่าน</u>
          </Typography>

          <Typography component="div" variant="body1" mt={1} sx={{ textAlign: 'justify' }}>
            &emsp;&emsp;ข้อมูลถือเป็นทรัพย์สินของบริษัทฯ ดังนั้น บริษัทฯ
            จึงให้ความสำคัญกับความปลอดภัยของข้อมูลส่วนบุคคลของท่านเป็นอย่างยิ่ง บริษัทฯ ตรวจสอบ
            และใช้มาตรการรักษาความปลอดภัยขององค์กร ทั้งทางกายภาพ
            และทางเทคนิคที่ทันสมัยอยู่เสมอเมื่อประมวลผลข้อมูลส่วนบุคคลของท่าน บริษัทฯ ได้วางนโยบาย
            และมาตรการควบคุมภายในเพื่อให้ท่านมั่นใจว่า ข้อมูลส่วนบุคคลของท่านจะไม่สูญหาย ถูกทำลายโดยไม่ตั้งใจ
            ถูกนำไปใช้ในทางที่ผิด ถูกเปิดเผย และเข้าถึงโดยบุคคลทั่วไปที่ไม่ใช่พนักงานที่ปฏิบัติหน้าที่ของบริษัทฯ
            โดยพนักงานของบริษัทฯ นั้นได้รับการอบรม และฝึกฝนให้จัดการกับข้อมูลส่วนบุคคลอย่างปลอดภัย
            หากพนักงานไม่ปฏิบัติตามที่ได้รับการอบรมฝึกฝนดังกล่าว พนักงานจะได้รับโทษทางวินัย
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            8. <u style={{ marginLeft: '1em' }}>หน้าที่ของท่าน</u>
          </Typography>

          <Typography component="div" variant="body1" mt={1} sx={{ textAlign: 'justify', textJustify: 'inter-word' }}>
            &emsp;&emsp;ท่านมีหน้าที่ตรวจสอบว่าข้อมูลส่วนบุคคลที่ท่านให้ไว้กับบริษัทฯ ไม่ว่าจะด้วยตัวของท่านเอง
            หรือในนามของท่าน มีความถูกต้อง และเป็นปัจจุบัน และมีหน้าที่ต้องแจ้งให้บริษัทฯ
            ทราบโดยเร็วที่สุดหากข้อมูลดังกล่าวมีการเปลี่ยนแปลง
          </Typography>

          <Typography
            component="div"
            variant="body1"
            mt={1}
            sx={{ textAlign: 'justify', textJustify: 'inter-character' }}>
            &emsp;&emsp;เมื่อท่านเข้าทำสัญญากับบริษัทฯแล้ว
            ท่านจะมีหน้าที่ตามสัญญาในการส่งมอบข้อมูลส่วนบุคคลให้แก่บริษัทฯ เพื่อให้ท่านสามารถใช้สิทธิทางกฎหมายได้
            การไม่ปฏิบัติตามหน้าที่ดังกล่าวอาจส่งผลให้ท่านสูญเสียสิทธิทางกฎหมาย
          </Typography>

          <Typography
            component="div"
            variant="body1"
            mt={1}
            sx={{ textAlign: 'justify', textJustify: 'inter-character' }}>
            &emsp;&emsp;ท่านมีความจำเป็นที่จะต้องส่งมอบข้อมูลส่วนบุคคล เช่น ข้อมูลการติดต่อ
            และข้อมูลการจ่ายเงินให้กับบริษัทฯ เพื่อให้บริษัทฯ สามารถเข้าทำสัญญากับท่านได้
            หากท่านไม่ส่งมอบข้อมูลส่วนบุคคลดังกล่าว อาจทำให้บริษัทฯ ไม่สามารถใช้สิทธิ
            และปฏิบัติตามภาระข้อผูกพันที่เกิดขึ้นจากสัญญาได้อย่างมีประสิทธิภาพ
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            9. <u style={{ marginLeft: '1em' }}>การแก้ไขประกาศความเป็นส่วนตัว</u>
          </Typography>

          <Typography component="div" variant="body1" mt={1} sx={{ textAlign: 'justify' }}>
            &emsp;&emsp;บริษัทฯ จะตรวจสอบประกาศความเป็นส่วนตัวอย่างสม่ำเสมอ ดังนั้น
            ประกาศความเป็นส่วนตัวนี้จึงอาจมีการแก้ไขเปลี่ยนแปลง โดยการแก้ไขเปลี่ยนแปลงครั้งล่าสุดนั้น
            สามารถดูได้จากวันที่ด้านล่างของประกาศความเป็นส่วนตัวฉบับนี้
          </Typography>
        </Box>

        <Box mt={2}>
          <Typography component="div" variant="subtitle1" sx={{ fontWeight: 700 }}>
            10. <u style={{ marginLeft: '1em' }}>ช่องทางการติดต่อบริษัทฯ</u>
          </Typography>

          <Typography component="div" variant="body1" mt={1}>
            &emsp;&emsp;ในกรณีที่ท่านมีข้อสงสัยเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของท่าน หรือต้องการใช้สิทธิใดๆ
            โปรดติดต่อ บริษัทฯ ที่
          </Typography>

          <Box mt={2} sx={{ border: '1px solid #000000' }}>
            <ul>
              <li>
                เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล <br />
                <div style={{ whiteSpace: 'pre-line' }}>Email: dataprotectionofficer@kasikorncarabao.com</div>
              </li>
            </ul>
          </Box>
        </Box>

        <Box mt={2} sx={{ textAlign: 'right' }}>
          ปรับปรุงครั้งล่าสุดเมื่อวันที่ 12 เมษายน 2566
        </Box>
      </Box>
    </>
  );
}
