import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import TestSlice from './slices/test-slice';
import ReadCSVMerchantListSlice from './slices/read-csv-file-list-slice';
import SaveReadCSVMerchantList from './slices/save-read-csv-file-slice';
import provinceListSlice from './slices/province-list-slice';
import districtListSlice from './slices/district-list-slice';
import regionListSlice from './slices/region-list-slice';

const store = configureStore({
  reducer: {
    test: TestSlice,
    ReadCSVMerchantList: ReadCSVMerchantListSlice,
    SaveReadCSVMerchantList: SaveReadCSVMerchantList,
    provinceList: provinceListSlice,
    districtList: districtListSlice,
    regionList: regionListSlice,
  },
});

// Types of root state and dispatch
type RootState = ReturnType<typeof store.getState>;
type AppDispath = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispath>();

export default store;
