import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Card, CardContent, IconButton } from '@mui/material';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import { TypeScreen } from '../../../utils/enum/responsive-enum';
import { slideProducts } from '../../../contentData/kbank-campaign';
import CarousalDetail from './carousal-detail-desktop';
import CarousalDetailMobile from './carousal-detail-mobile';
import { useNavigate } from 'react-router-dom';

export interface Props {
  type: string;
}

const properties = {
  prevArrow: (
    <IconButton>
      <ArrowBackIos sx={{ fontSize: 50, color: '#9e9e9e' }} />
    </IconButton>
  ),
  nextArrow: (
    <IconButton sx={{ mr: -1.5 }}>
      <ArrowForwardIos sx={{ fontSize: 50, color: '#9e9e9e' }} />
    </IconButton>
  ),
};

const products: any[] = slideProducts;
export default function CarousalImage(props: Props) {
  const navigate = useNavigate();
  return (
    <>
      {props.type === TypeScreen.DESKTOP && (
        <Card>
          <CardContent sx={{ borderRadius: '8px' }}>
            <Box sx={{ mb: '-2.5em' }}>
              <Slide indicators={true} {...properties}>
                {products.map((skuContent) => (
                  <Box
                    onClick={() => {
                      navigate('/services', {
                        state: {
                          productSamsung: skuContent.brand === 'samsung' ? true : false,
                          productVivo: skuContent.brand === 'vivo' ? true : false,
                        },
                      });
                    }}
                    sx={{ cursor: 'pointer' }}>
                    <CarousalDetail skuContent={skuContent}></CarousalDetail>
                  </Box>
                ))}
              </Slide>
            </Box>
          </CardContent>
        </Card>
      )}

      {props.type !== TypeScreen.DESKTOP && (
        <Card>
          <CardContent sx={{ borderRadius: '8px' }}>
            <Box className="slide-container" sx={{ mb: '-2.5em' }}>
              <Slide indicators={true}>
                {products.map((skuContent) => (
                  <Box
                    onClick={() => {
                      navigate('/services', {
                        state: {
                          productSamsung: skuContent.brand === 'samsung' ? true : false,
                          productVivo: skuContent.brand === 'vivo' ? true : false,
                        },
                      });
                    }}>
                    <CarousalDetailMobile skuContent={skuContent}></CarousalDetailMobile>
                  </Box>
                ))}
              </Slide>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
}
