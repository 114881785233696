export const contactUSContent = {
  header: 'ติดต่อเรา',
  address: {
    name: 'บริษัท กสิกร คาราบาว จำกัด',
    address1: 'เลขที่ 397/1 อาคารกรทอง ชั้น 3',
    address2: 'ถนนสีลม',
    subDistrict: 'แขวงสีลม',
    district: ' เขตบางรัก',
    province: 'กรุงเทพมหานคร',
    zipCode: '10500',
  },
  office: {
    title: 'เวลาทำการปกติ',
    day: 'วันจันทร์ - ศุกร์',
    time: 'เวลา 8.30 - 17.00 น.',
  },
  customerServiceCenter: {
    title: 'ศูนย์บริการลูกค้า',
    day: 'ทุกวัน',
    time: 'เวลา 9.30 - 19.00 น.',
  },
  email: {
    title: 'Email:',
    email: 'info@kasikorncarabao.com',
  },
  line: {
    title: 'Line Official:',
    line: '@kbao',
  },
};
