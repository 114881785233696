import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import SearchBranchesDeskTop from './search-branches-desktop';
import SearchBranchesMobile from './search-branches-mobile';

export default function SearchBranchesContent() {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {!isTabletOrMobile && <SearchBranchesDeskTop />}
      {isTabletOrMobile && (
        <>{isPortrait ? <SearchBranchesMobile landscape={true} /> : <SearchBranchesMobile landscape={false} />}</>
      )}
    </>
  );
}
