import { Box, Grid, Typography } from '@mui/material';
import { aboutUsContent } from '../../contentData/about-us';
import imgTDxKBankInfo from '../../assets/images/about/td-kbank-info.jpg';
import BoxDesktop from '../commons/ui/box-desktop';

import kbankTD01 from '../../assets/images/about/kbank-td-01.png';
import kbankTD02 from '../../assets/images/about/kbank-td-02.jpg';
import kbankTD03 from '../../assets/images/about/kbank-td-03.jpg';
import kbankTD04 from '../../assets/images/about/kbank-td-04.jpg';
import kbankTD05 from '../../assets/images/about/kbank-td-05.jpg';
import DialogImage from './dialog-image';
import { useState } from 'react';

export default function AboutContentDeskTop() {
  const [openDialog, setOpenDialog] = useState(false);
  const [imgDialog, setImgDialog] = useState('');
  const handleOpen = (img: string) => {
    setOpenDialog(true);
    setImgDialog(img);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setImgDialog('');
  };

  return (
    <>
      <BoxDesktop>
        <Typography component="div" variant="h3" sx={{ color: '#0E7F42', fontWeight: 700 }}>
          {aboutUsContent.header}
        </Typography>
        <Typography component="div" sx={{ fontSize: 24, fontWeight: 700, mt: 4 }}>
          {aboutUsContent.title}
        </Typography>
        <Typography component="div" sx={{ fontSize: 16, mt: 2 }}>
          {aboutUsContent.content1}
          {aboutUsContent.content2}
        </Typography>

        <Box mt={10} mb={5} ml="15%" mr="15%">
          <Grid container>
            <Grid xs={4} sx={{ textAlign: 'center', pr: 1 }}>
              <img
                src={kbankTD05}
                width="100%"
                style={{ cursor: 'pointer', minHeight: 160, maxHeight: 196, borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD05)}
              />
            </Grid>
            <Grid xs={4} sx={{ textAlign: 'center', pl: 3, pr: 3 }}>
              <img
                src={kbankTD01}
                width="100%"
                style={{ cursor: 'pointer', minHeight: 160, maxHeight: 196, borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD01)}
              />
            </Grid>
            <Grid xs={4} sx={{ textAlign: 'center', pl: 1 }}>
              <img
                src={kbankTD02}
                width="100%"
                style={{ cursor: 'pointer', minHeight: 160, maxHeight: 196, borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD02)}
              />
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <Grid xs={6} sx={{ textAlign: 'center', pr: 2 }}>
              <img
                src={kbankTD04}
                width="100%"
                style={{ cursor: 'pointer', borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD04)}
              />
            </Grid>
            <Grid xs={6} sx={{ textAlign: 'center', pl: 2 }}>
              <img
                src={kbankTD03}
                width="100%"
                style={{ cursor: 'pointer', borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD03)}
              />
            </Grid>
          </Grid>

          <DialogImage open={openDialog} imgInfo={imgDialog} onClickClose={handleClose} />
        </Box>

        <Box p={2} pl={4}>
          <Typography component="div" variant="h4" sx={{ mt: 4 }}>
            {aboutUsContent.policy.title}
          </Typography>

          <Typography component="div" variant="h4" sx={{ mb: 4 }}>
            {aboutUsContent.policy.content}
          </Typography>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <img src={imgTDxKBankInfo} alt="imgTDxKBank Info" width="100%" style={{ maxWidth: 950 }} />
          </Box>
        </Box>
      </BoxDesktop>
    </>
  );
}
