import { Box } from '@mui/material';
import PolicyContent from '../components/policy/policy-content';
import { Screen } from '../utils/enum/responsive-enum';

export default function Policy() {
  return (
    <>
      <Box sx={{ maxWidth: Screen.MAXWIDTH, margin: 'auto' }}>
        <PolicyContent />
      </Box>
    </>
  );
}
