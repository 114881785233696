import { Typography } from '@mui/material';
import { policyContent } from '../../contentData/policy';
import BoxDesktop from '../commons/ui/box-desktop';
import TextContent from './text-content';

export default function CookieContentDeskTop() {
  return (
    <>
      <BoxDesktop>
        <Typography component="div" variant="h4">
          {policyContent.header}
        </Typography>

        <TextContent />
      </BoxDesktop>
    </>
  );
}
