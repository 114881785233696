import { ArrowDropDown } from '@mui/icons-material';
import { Autocomplete, CircularProgress, createFilterOptions, InputAdornment, TextField } from '@mui/material';
import React, { Fragment } from 'react';

export interface selectProps {
  placeholder: string;
  headerText?: string;
  defaultValue: any;
  items: any[];
  onChange: (value: any) => void;
}

export default function AutocompleteItems(props: selectProps) {
  const [value, setValue] = React.useState(props.defaultValue);
  const [loading] = React.useState(false);
  let options: any = props.items && props.items.length > 0 ? props.items : [];
  const handleChangeValue = async (event: any, option: any, reason: any) => {
    if (option && reason === 'selectOption') {
      setValue(option);
      return props.onChange(option);
    }
  };

  const statusFilterOptions = createFilterOptions({ stringify: (option: any) => option.code + option.name });

  const statusRenderListItem = (props: any, option: any) => {
    return (
      <li {...props} key={option.code}>
        {option.name}
      </li>
    );
  };

  const onInputChange = async (event: any) => {
    if (event && event.keyCode && event.keyCode === 13) {
      return false;
    }
  };

  const autocompleteRenderInput = (params: any, helperText: string = '', error: boolean = false) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              <InputAdornment position="end">
                <ArrowDropDown />
              </InputAdornment>
            </Fragment>
          ),
        }}
        variant="outlined"
        size="small"
        fullWidth
        error={error}
        helperText={helperText}
        placeholder={props.placeholder}
        value={params.value}
        sx={{
          '& .MuiInputBase-root': {
            pr: '10px !important',
            minHeight: '45px !important',
          },
        }}
      />
    );
  };

  return (
    <>
      <Autocomplete
        value={value}
        fullWidth
        freeSolo
        loadingText="loading..."
        loading={loading}
        options={options}
        filterOptions={statusFilterOptions}
        renderOption={statusRenderListItem}
        onChange={(event: any, option: any, reason: any) => handleChangeValue(event, option, reason)}
        onInputChange={onInputChange}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        isOptionEqualToValue={(option, value) => option.code === value.code}
        renderInput={(option) => autocompleteRenderInput(option)}
      />
    </>
  );
}
