import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

interface Props {
  regionListOption: any;
  onComplete: (value: any) => void;
}
export default function FilterRegion({ regionListOption, onComplete }: Props) {
  const [loading] = useState(false);

  const [selectRegion, setSelectRegion] = useState('');

  const autocompleteRenderInput = (params: any) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
        }}
        placeholder={'ภาค'}
        variant="standard"
        size="small"
        fullWidth
        sx={{ borderBottom: '1px solid #6D6D6D' }}
      />
    );
  };

  const regionRenderListItem = (props: any, option: any) => {
    return <li {...props}>{option.regionName}</li>;
  };

  const handleChangeItemRegion = async (event: any, option: any, reason: any) => {
    if (option) {
      setSelectRegion(option);

      return onComplete(option);
    }
  };

  const onInputChange = async (event: any, value: string, reason: string) => {
    if (event && event.keyCode && event.keyCode === 13) {
      return false;
    }
    if (value === '' && reason === 'input') {
      setSelectRegion('');
      return onComplete(null);
    }
  };

  useEffect(() => {
    if (selectRegion) {
      setSelectRegion('');
    }
  }, [regionListOption]);

  return (
    <>
      <Autocomplete
        id="selmMerchantByRegion"
        disableClearable
        value={selectRegion}
        loadingText="กำลังโหลด..."
        loading={loading}
        options={regionListOption}
        renderOption={regionRenderListItem}
        renderInput={(option) => autocompleteRenderInput(option)}
        getOptionLabel={(option: any) => (option.regionName ? option.regionName : '')}
        onChange={handleChangeItemRegion}
        onInputChange={onInputChange}
      />
    </>
  );
}
