import { Box, Grid, Typography } from '@mui/material';
import { aboutUsContent } from '../../contentData/about-us';
import imgTDxKBankInfo from '../../assets/images/about/td-kbank-info.jpg';
import BoxMobile from '../commons/ui/box-mobile';

import kbankTD01 from '../../assets/images/about/kbank-td-01.png';
import kbankTD02 from '../../assets/images/about/kbank-td-02.jpg';
import kbankTD03 from '../../assets/images/about/kbank-td-03.jpg';
import kbankTD04 from '../../assets/images/about/kbank-td-04.jpg';
import kbankTD05 from '../../assets/images/about/kbank-td-05.jpg';
import DialogImage from './dialog-image';
import { useState } from 'react';

interface Props {
  landscape: boolean;
}

export default function AboutContentMobile({ landscape }: Props) {
  const [openDialog, setOpenDialog] = useState(false);
  const [imgDialog, setImgDialog] = useState('');
  const handleOpen = (img: string) => {
    setOpenDialog(true);
    setImgDialog(img);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setImgDialog('');
  };

  return (
    <>
      <BoxMobile>
        <Typography component="div" variant="h4" sx={{ color: '#0E7F42', fontWeight: 700, mt: 4 }}>
          {aboutUsContent.header}
        </Typography>

        <Typography component="div" sx={{ fontSize: 16, fontWeight: 700, mt: 2 }}>
          {aboutUsContent.title}
        </Typography>

        <Typography component="div" sx={{ fontSize: 14 }}>
          {aboutUsContent.content1}
          {aboutUsContent.content2}
        </Typography>

        <Box mt={5} mb={5} ml="2%" mr="2%">
          <Grid container>
            <Grid xs={4} sx={{ textAlign: 'center', pr: '2%' }}>
              <img
                src={kbankTD05}
                width="100%"
                style={{ cursor: 'pointer', minHeight: 100, maxHeight: 178, borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD05)}
              />
            </Grid>
            <Grid xs={4} sx={{ textAlign: 'center', pl: '2%', pr: '2%' }}>
              <img
                src={kbankTD01}
                width="100%"
                style={{ cursor: 'pointer', minHeight: 100, maxHeight: landscape ? 178 : '83%', borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD01)}
              />
            </Grid>
            <Grid xs={4} sx={{ textAlign: 'center', pl: '2%' }}>
              <img
                src={kbankTD02}
                width="100%"
                style={{ cursor: 'pointer', minHeight: 100, maxHeight: 178, borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD02)}
              />
            </Grid>
          </Grid>

          <Grid container mt="2%">
            <Grid xs={6} sx={{ textAlign: 'center', pr: '2%' }}>
              <img
                src={kbankTD04}
                width="100%"
                style={{ cursor: 'pointer', borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD04)}
              />
            </Grid>
            <Grid xs={6} sx={{ textAlign: 'center', pl: '2%' }}>
              <img
                src={kbankTD03}
                width="100%"
                style={{ cursor: 'pointer', borderRadius: 4 }}
                onClick={() => handleOpen(kbankTD03)}
              />
            </Grid>
          </Grid>

          <DialogImage open={openDialog} imgInfo={imgDialog} onClickClose={handleClose} />
        </Box>

        {/* <Box mt={5} mb={5} ml="10%" mr="10%">
          <Grid container>
            <Grid xs={12} sx={{ textAlign: 'center' }}>
              <img src={kbankTD01} width="100%" />
            </Grid>
          </Grid>

          <Grid container>
            <Grid xs={2.4} sx={{ textAlign: 'center', pr: 1 }}>
              <img
                src={kbankTD01}
                width="100%"
                height="50"
                style={{ cursor: 'pointer', width: '100%', height: isTabletOrMobile ? 75 : 56 }}
                onClick={() => handleOpen(kbankTD01)}
              />
            </Grid>
            <Grid xs={2.4} sx={{ textAlign: 'center', pr: 1 }}>
              <img
                src={kbankTD02}
                width="100%"
                height="50"
                style={{ cursor: 'pointer', width: '100%', height: isTabletOrMobile ? 75 : 56 }}
                onClick={() => handleOpen(kbankTD02)}
              />
            </Grid>
            <Grid xs={2.4} sx={{ textAlign: 'center', pr: 1 }}>
              <img
                src={kbankTD03}
                width="100%"
                style={{ cursor: 'pointer', width: '100%', height: isTabletOrMobile ? 75 : 56 }}
                onClick={() => handleOpen(kbankTD03)}
              />
            </Grid>
            <Grid xs={2.4} sx={{ textAlign: 'center', pr: 1 }}>
              <img
                src={kbankTD04}
                width="100%"
                style={{ cursor: 'pointer', width: '100%', height: isTabletOrMobile ? 75 : 56 }}
                onClick={() => handleOpen(kbankTD04)}
              />
            </Grid>
            <Grid xs={2.4} sx={{ textAlign: 'center' }}>
              <img
                src={kbankTD05}
                width="100%"
                style={{ cursor: 'pointer', width: '100%', height: isTabletOrMobile ? 75 : 56 }}
                onClick={() => handleOpen(kbankTD05)}
              />
            </Grid>
          </Grid>

          <DialogImage open={openDialog} imgInfo={imgDialog} onClickClose={handleClose} />
        </Box> */}

        <Box p={2}>
          <Typography component="div" sx={{ fontSize: 16, fontWeight: 700, mt: 2 }}>
            {aboutUsContent.policy.title}
          </Typography>

          <Typography component="div" sx={{ fontSize: 16, fontWeight: 700 }}>
            {aboutUsContent.policy.content}
          </Typography>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <img src={imgTDxKBankInfo} alt="imgTDxKBank Info" width="100%" style={{ maxWidth: 950 }} />
          </Box>
        </Box>
      </BoxMobile>
    </>
  );
}
