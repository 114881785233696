import { useMediaQuery } from 'react-responsive';
import { Responsive } from '../../utils/enum/responsive-enum';
import CookieContentDeskTop from './policy-content-desktop';
import CookieContentMobile from './policy-content-mobile';

export default function PolicyContent() {
  const isTabletOrMobile = useMediaQuery({ query: `(max-width: ${Responsive.TABLE_OR_MOBILE})` });
  const isPortrait = useMediaQuery({ query: `(orientation: ${Responsive.PORTRAIT})` });

  return (
    <>
      {!isTabletOrMobile && <CookieContentDeskTop />}
      {isTabletOrMobile && (
        <>{isPortrait ? <CookieContentMobile landscape={true} /> : <CookieContentMobile landscape={false} />}</>
      )}
    </>
  );
}
